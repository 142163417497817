import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  restartStatusErrorAdmin: null,
  restartPermissionsFlow: null,
  restartCreateUserFlow: null,
  restartRemoveUserFlow: null,
  restartEditUserFlow: null,
  restartRemovePermissionFlow: null,
  restartEditPermissionFlow: null,
  restartInviteVolunteerFlow: null,
  restartCreateTestFlow: null,
  restartEditTestFlow: null,
  restartRemoveTestFlow: null,
  restartCreateBatteryFlow: null,
  restartEditBatteryFlow: null,
  restartRemoveBatteryFlow: null,
  restartAddRelationTestBatteryFlow: null,
  restartRemoveRelationTestBatteryFlow: null,
  restartVerifyUser: null,
  restartCopyLinkVolunteer: null,
  restartCreateTeamFlow: null,
  restartEditTeamFlow: null,
  restartRemoveTeamFlow: null,
  restartAddMemberTeamFlow: null,
  restartRemoveMemberTeamFlow: null,
  restartResendEmailFlow: null,
  restartCreateLabelFlow: null,
  restartEditLabelFlow: null,
  restartRemoveLabelFlow: null,

  requestGetListOfUsers: null,
  receiveGetListOfUsers: ['data'],
  receiveGetListOfUsersError: ['error'],

  requestChangeUserPermission: null,
  receiveChangeUserPermission: ['data'],
  receiveChangeUserPermissionError: ['error'],

  requestCreateUser: null,
  receiveCreateUser: ['data'],
  receiveCreateUserError: ['error'],

  requestPermissions: null,
  receivePermissions: ['data'],
  receivePermissionsError: ['error'],

  requestCreatePermission: null,
  receiveCreatePermission: ['data'],
  receiveCreatePermissionError: ['error'],

  requestRemovePermission: null,
  receiveRemovePermission: ['data', 'permissionId'],
  receiveRemovePermissionError: ['error'],

  requestEditPermission: null,
  receiveEditPermission: ['data'],
  receiveEditPermissionError: ['error'],

  requestRemoveUser: null,
  receiveRemoveUser: ['data', 'userId'],
  receiveRemoveUserError: ['error'],

  requestEditUser: null,
  receiveEditUser: ['data'],
  receiveEditUserError: ['error'],

  requestInviteVolunteer: null,
  receiveInviteVolunteer: ['data'],
  receiveInviteVolunteerError: ['error'],

  requestCreateTest: null,
  receiveCreateTest: ['data'],
  receiveCreateTestError: ['error'],

  requestTests: null,
  receiveTests: ['data'],
  receiveTestsError: ['error'],

  requestEditTest: null,
  receiveEditTest: ['data'],
  receiveEditTestError: ['error'],

  requestRemoveTest: null,
  receiveRemoveTest: ['data'],
  receiveRemoveTestError: ['error'],

  requestReorganizeTests: null,
  receiveReorganizeTests: ['data'],
  receiveReorganizeTestsError: ['error'],

  requestCreateBattery: null,
  receiveCreateBattery: ['data'],
  receiveCreateBatteryError: ['error'],

  requestBatteries: null,
  receiveBatteries: ['data'],
  receiveBatteriesError: ['error'],

  requestEditBattery: null,
  receiveEditBattery: ['data'],
  receiveEditBatteryError: ['error'],

  requestRemoveBattery: null,
  receiveRemoveBattery: ['data'],
  receiveRemoveBatteryError: ['error'],

  requestAddRelationTestBattery: null,
  receiveAddRelationTestBattery: ['data'],
  receiveAddRelationTestBatteryError: ['error'],

  requestRemoveRelationTestBattery: null,
  receiveRemoveRelationTestBattery: ['data'],
  receiveRemoveRelationTestBatteryError: ['error'],

  requestVerifyUser: null,
  receiveVerifyUser: ['data'],
  receiveVerifyUserError: ['error'],

  requestListVolunteers: null,
  receiveListVolunteers: ['data'],
  receiveListVolunteersError: ['error'],

  requestResendVolunteer: null,
  receiveResendVolunteer: ['data'],
  receiveResendVolunteerError: ['error'],

  requestCopyLinkVolunteer: null,
  receiveCopyLinkVolunteer: ['data'],
  receiveCopyLinkVolunteerError: ['error'],

  requestCreateTeam: null,
  receiveCreateTeam: ['data'],
  receiveCreateTeamError: ['error'],

  requestEditTeam: null,
  receiveEditTeam: ['data'],
  receiveEditTeamError: ['error'],

  requestRemoveTeam: null,
  receiveRemoveTeam: ['data'],
  receiveRemoveTeamError: ['error'],

  requestTeams: null,
  receiveTeams: ['data'],
  receiveTeamsError: ['error'],

  requestAddMemberTeam: null,
  receiveAddMemberTeam: ['data'],
  receiveAddMemberTeamError: ['error'],

  requestRemoveMemberTeam: null,
  receiveRemoveMemberTeam: ['data'],
  receiveRemoveMemberTeamError: ['error'],

  requestLabels: null,
  receiveLabels: ['data'],
  receiveLabelsError: ['error'],

  requestCreateLabel: null,
  receiveCreateLabel: ['data'],
  receiveCreateLabelError: ['error'],

  requestEditLabel: null,
  receiveEditLabel: ['data'],
  receiveEditLabelError: ['error'],

  requestRemoveLabel: null,
  receiveRemoveLabel: ['data'],
  receiveRemoveLabelError: ['error'],

  requestFeedbacks: null,
  receiveFeedbacks: ['data'],
  receiveFeedbacksError: ['error'],

  requestCreateBatteryTestConnection: null,
  receiveCreateBatteryTestConnection: null,
  receiveCreateBatteryTestConnectionError: ['error'],
  restartCreateBatteryTestConnectionFlow: null,

  requestConfigParams: null,
  receiveConfigParamsSuccess: null,
  receiveConfigParamsError: ['error'],
  restartConfigParams: null,

  requestConfigDemos: null,
  receiveConfigDemosSuccess: null,
  receiveConfigDemosError: ['error'],
  restartConfigDemos: null,

  requestApplications: null,
  receiveApplications: ['data'],
  receiveApplicationsError: ['error'],

  requestEditApplication: null,
  receiveEditApplication: null,
  receiveEditApplicationError: ['error'],

  requestCreateApplication: null,
  receiveCreateApplication: null,
  receiveCreateApplicationError: ['error'],

  requestDeleteApplication: null,
  receiveDeleteApplication: null,
  receiveDeleteApplicationError: ['error'],

  resetApplicationFlow: null,
});
