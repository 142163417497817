import { createReducer } from 'reduxsauce';
import { Types } from './actions'

export const INITIAL_STATE = {
  userInformation: null,

  patientsData: null,
  patientsLoading: false,
  patientsError: null,

  createPatientSuccess: null,
  createPatientLoading: false,
  createPatientError: null,

  editPatientSuccess: null,
  editPatientLoading: false,
  editPatientError: null,

  invitePatientSuccess: null,
  invitePatientLoading: false,
  invitePatientError: null,

  deletePatientSuccess: null,
  deletePatientError: null,
  deletePatientLoading: false,

  editMyAccountSuccess: false,
  editMyAccountLoading: false,
  editMyAccountError: null,

  confirmPasswordSuccess: false,
  confirmPasswordLoading: false,
  confirmPasswordError: null,

  statusErrorUser: null,

  searchContentData: null,
  searchContentLoading: false,
  searchContentError: null,

  helpSuccess: false,
  helpLoading: false,
  helpError: null,

  batteriesExecutedData: null,
  batteriesExecutedLoading: false,
  batteriesExecutedError: null,

  allLabelsData: null,
  allLabelsLoading: false,
  allLabelsError: null,

  myLabelsData: null,
  myLabelsLoading: false,
  myLabelsError: null,

  createMyLabelSuccess: null,
  createMyLabelLoading: false,
  createMyLabelError: null,

  editMyLabelSuccess: null,
  editMyLabelLoading: false,
  editMyLabelError: null,

  removeMyLabelSuccess: null,
  removeMyLabelLoading: false,
  removeMyLabelError: null,

  inviteLotPatientSuccess: null,
  inviteLotPatientLoading: false,
  inviteLotPatientError: null,

  patientAccountData: null,
  patientAccountLoading: false,
  patientAccountError: null
}

const resetUserState = () => ({
  userInformation: null,
  patientsData: null,
  patientsLoading: false,
  patientsError: null,
  createPatientSuccess: null,
  createPatientLoading: false,
  createPatientError: null,
  invitePatientSuccess: null,
  invitePatientLoading: false,
  invitePatientError: null,
  editMyAccountSuccess: false,
  editMyAccountLoading: false,
  editMyAccountError: null,
  confirmPasswordSuccess: false,
  confirmPasswordLoading: false,
  confirmPasswordError: null,
  statusErrorUser: null
})

const restartConfirmPasswordFlow = (state = INITIAL_STATE) => ({
  ...state,
  confirmPasswordSuccess: false,
  confirmPasswordLoading: false,
  confirmPasswordError: null,
})

const restartEditMyAccountFlow = (state = INITIAL_STATE) => ({
  ...state,
  editMyAccountSuccess: false,
  editMyAccountLoading: false,
  editMyAccountError: null,
})

const restartCreatePatientFlow = (state = INITIAL_STATE) => ({
  ...state,
  createPatientSuccess: null,
  createPatientLoading: false,
  createPatientError: null
})

const restartEditPatientFlow = (state = INITIAL_STATE) => ({
  ...state,
  editPatientSuccess: null,
  editPatientLoading: false,
  editPatientError: null
})

const restartInvitePatientFlow = (state = INITIAL_STATE) => ({
  ...state,
  invitePatientSuccess: null,
  invitePatientLoading: false,
  invitePatientError: null,
})

const restartInviteLotPatientFlow = (state = INITIAL_STATE) => ({
  ...state,
  inviteLotPatientSuccess: null,
  inviteLotPatientLoading: false,
  inviteLotPatientError: null,
})

const restartDeletePatientFlow = (state = INITIAL_STATE) => ({
  ...state,
  deletePatientSuccess: null,
  deletePatientLoading: false,
  deletePatientError: null,
})

const restartCreateMyLabelFlow = (state = INITIAL_STATE) => ({
  ...state,
  createMyLabelSuccess: null,
  createMyLabelLoading: false,
  createMyLabelError: null
})

const restartEditMyLabelFlow = (state = INITIAL_STATE) => ({
  ...state,
  editMyLabelSuccess: null,
  editMyLabelLoading: false,
  editMyLabelError: null
})

const restartRemoveMyLabelFlow = (state = INITIAL_STATE) => ({
  ...state,
  removeMyLabelSuccess: null,
  removeMyLabelLoading: false,
  removeMyLabelError: null
})

// set User Information
const setUserInformation = (state = INITIAL_STATE, action) => ({
  ...state,
  userInformation: action.data
})
// get Patients

const requestPatients = (state = INITIAL_STATE) => ({
  ...state,
  patientsData: null,
  patientsLoading: true,
  patientsError: null
})

const receivePatients = (state = INITIAL_STATE, action) => ({
  ...state,
  patientsData: action.data,
  patientsLoading: false,
  patientsError: null
})

const receivePatientsError = (state = INITIAL_STATE, action) => ({
  ...state,
  patientsData: null,
  patientsLoading: false,
  patientsError: action.error,
  statusErrorUser: action.error.status
})

// create Patients

const requestCreatePatient = (state = INITIAL_STATE) => ({
  ...state,
  createPatientSuccess: null,
  createPatientLoading: true,
  createPatientError: null
})

const receiveCreatePatient = (state = INITIAL_STATE, action) => ({
  ...state,
  patientsData: state.patientsData ? state.patientsData.concat([action.data]) : [action.data],
  createPatientSuccess: action.data,
  createPatientLoading: false,
  createPatientError: null
})

const receiveCreatePatientError = (state = INITIAL_STATE, action) => ({
  ...state,
  createPatientSuccess: null,
  createPatientLoading: false,
  createPatientError: action.error,
  statusErrorUser: action.error.status
})

const requestEditPatient = (state = INITIAL_STATE) => ({
  ...state,
  editPatientSuccess: null,
  editPatientLoading: true,
  editPatientError: null
})

const receiveEditPatient = (state = INITIAL_STATE, action) => ({
  ...state,
  patientsData: state.patientsData.map((patient) => patient.id === action.data.id ? action.data : patient),
  editPatientSuccess: action.data,
  editPatientLoading: false,
  editPatientError: null
})

const receiveEditPatientError = (state = INITIAL_STATE, action) => ({
  ...state,
  editPatientSuccess: null,
  editPatientLoading: false,
  editPatientError: action.error,
  statusErrorUser: action.error.status
})

// invite Patient

const requestInvitePatient = (state = INITIAL_STATE) => ({
  ...state,
  invitePatientSuccess: null,
  invitePatientLoading: true,
  invitePatientError: null,
})

const receiveInvitePatient = (state = INITIAL_STATE, action) => ({
  ...state,
  invitePatientSuccess: action.data,
  invitePatientLoading: false,
  invitePatientError: null,
})

const receiveInvitePatientError = (state = INITIAL_STATE, action) => ({
  ...state,
  invitePatientError: action.error,
  invitePatientSuccess: null,
  invitePatientLoading: null,
  statusErrorUser: action.error.status
})

const requestInviteLotPatient = (state = INITIAL_STATE) => ({
  ...state,
  inviteLotPatientSuccess: null,
  inviteLotPatientLoading: true,
  inviteLotPatientError: null,
})

const receiveInviteLotPatient = (state = INITIAL_STATE, action) => ({
  ...state,
  inviteLotPatientSuccess: action.data,
  inviteLotPatientLoading: false,
  inviteLotPatientError: null,
})

const receiveInviteLotPatientError = (state = INITIAL_STATE, action) => ({
  ...state,
  inviteLotPatientSuccess: null,
  inviteLotPatientLoading: false,
  inviteLotPatientError: action.error,
})


// delete patient
const requestDeletePatient = (state = INITIAL_STATE) => ({
  ...state,
  deletePatientSuccess: null,
  deletePatientLoading: true,
  deletePatientError: null,
})

const receiveDeletePatient = (state = INITIAL_STATE, action) => ({
  ...state,
  deletePatientSuccess: action.data,
  patientsData: state.patientsData.filter((patient) => {
    return patient.id !== action.data.id
  }),
  deletePatientLoading: false,
  deletePatientError: null,
})

const receiveDeletePatientError = (state = INITIAL_STATE, action) => ({
  ...state,
  deletePatientError: action.error,
  deletePatientSuccess: null,
  deletePatientLoading: null,
  statusErrorUser: action.error.status
})

// Edit My Account
const requestEditMyAccount = (state = INITIAL_STATE) => ({
  ...state,
  editMyAccountSuccess: false,
  editMyAccountLoading: true,
  editMyAccountError: null,

})
const receiveEditMyAccount = (state = INITIAL_STATE, action) => ({
  ...state,
  userInformation: action.data,
  editMyAccountSuccess: true,
  editMyAccountLoading: false,
  editMyAccountError: null,
})

const receiveEditMyAccountError = (state = INITIAL_STATE, action) => ({
  ...state,
  editMyAccountSuccess: false,
  editMyAccountLoading: false,
  editMyAccountError: action.error,
  statusErrorUser: action.error.status
})

// confirm Password
const requestConfirmPassword = (state = INITIAL_STATE) => ({
  ...state,
  confirmPasswordSuccess: false,
  confirmPasswordLoading: true,
  confirmPasswordError: null,
})

const receiveConfirmPassword = (state = INITIAL_STATE) => ({
  ...state,
  confirmPasswordSuccess: true,
  confirmPasswordLoading: false,
  confirmPasswordError: null,
})

const receiveConfirmPasswordError = (state = INITIAL_STATE, action) => ({
  ...state,
  confirmPasswordSuccess: false,
  confirmPasswordLoading: false,
  confirmPasswordError: action.error,
  statusErrorUser: action.error.status
})

// search content
const requestSearchContent = (state = INITIAL_STATE) => ({
  ...state,
  searchContentData: null,
  searchContentLoading: true,
  searchContentError: null,
})

const receiveSearchContent = (state = INITIAL_STATE, action) => ({
  ...state,
  searchContentData: action.data,
  searchContentLoading: false,
  searchContentError: null,
})

const receiveSearchContentError = (state = INITIAL_STATE, action) => ({
  ...state,
  searchContentData: null,
  searchContentLoading: false,
  searchContentError: action.error,
})

const restartHelpFlow = (state = INITIAL_STATE) => ({
  ...state,
  helpSuccess: false,
  helpLoading: false,
  helpError: null,
})
const requestHelp = (state = INITIAL_STATE) => ({
  ...state,
  helpSuccess: false,
  helpLoading: true,
  helpError: null,
})

const receiveHelp = (state = INITIAL_STATE) => ({
  ...state,
  helpSuccess: true,
  helpLoading: false,
  helpError: null,
})

const receiveHelpError = (state = INITIAL_STATE, action) => ({
  ...state,
  helpSuccess: false,
  helpLoading: false,
  helpError: action.error,
})

const requestBatteriesExecuted = (state = INITIAL_STATE) => ({
  ...state,
  batteriesExecutedData: null,
  batteriesExecutedLoading: true,
  batteriesExecutedError: null,
})

const receiveBatteriesExecuted = (state = INITIAL_STATE, action) => ({
  ...state,
  batteriesExecutedData: action.data,
  batteriesExecutedLoading: false,
  batteriesExecutedError: null,
})

const receiveBatteriesExecutedError = (state = INITIAL_STATE, action) => ({
  ...state,
  batteriesExecutedData: null,
  batteriesExecutedLoading: false,
  batteriesExecutedError: action.error,
})

const requestAllLabels = (state = INITIAL_STATE) => ({
  ...state,
  allLabelsData: null,
  allLabelsLoading: true,
  allLabelsError: null
})

const receiveAllLabels = (state = INITIAL_STATE, action) => ({
  ...state,
  allLabelsData: action.data,
  allLabelsLoading: false,
  allLabelsError: null,
})

const receiveAllLabelsError = (state = INITIAL_STATE, action) => ({
  ...state,
  allLabelsData: null,
  allLabelsLoading: false,
  allLabelsError: action.error,
})

const requestMyLabels = (state = INITIAL_STATE) => ({
  ...state,
  myLabelsData: null,
  myLabelsLoading: true,
  myLabelsError: null
})

const receiveMyLabels = (state = INITIAL_STATE, action) => ({
  ...state,
  myLabelsData: action.data,
  myLabelsLoading: false,
  myLabelsError: null,
})

const receiveMyLabelsError = (state = INITIAL_STATE, action) => ({
  ...state,
  myLabelsData: null,
  myLabelsLoading: false,
  myLabelsError: action.error,
})

const requestCreateMyLabel = (state = INITIAL_STATE) => ({
  ...state,
  createMyLabelSuccess: null,
  createMyLabelLoading: true,
  createMyLabelError: null,
})

const receiveCreateMyLabel = (state = INITIAL_STATE, action) => ({
  ...state,
  createMyLabelSuccess: action.data,
  createMyLabelLoading: false,
  createMyLabelError: null,
  myLabelsData: state.myLabelsData ? state.myLabelsData.concat([action.data]) : [action.data]
})

const receiveCreateMyLabelError = (state = INITIAL_STATE, action) => ({
  ...state,
  createMyLabelSuccess: null,
  createMyLabelLoading: false,
  createMyLabelError: action.error,
})

const requestEditMyLabel = (state = INITIAL_STATE) => ({
  ...state,
  editMyLabelSuccess: null,
  editMyLabelLoading: true,
  editMyLabelError: null,
})

const receiveEditMyLabel = (state = INITIAL_STATE, action) => ({
  ...state,
  editMyLabelSuccess: action.data,
  editMyLabelLoading: false,
  editMyLabelError: null,
  myLabelsData: state.myLabelsData?.map((label) => {
    return label.id === action.data.id ? action.data : label
  })
})

const receiveEditMyLabelError = (state = INITIAL_STATE, action) => ({
  ...state,
  editMyLabelSuccess: null,
  editMyLabelLoading: false,
  editMyLabelError: action.error,
})

const requestRemoveMyLabel = (state = INITIAL_STATE) => ({
  ...state,
  removeLabelSuccess: null,
  removeLabelLoading: true,
  removeLabelError: null,
})

const receiveRemoveMyLabel = (state = INITIAL_STATE, action) => ({
  ...state,
  removeMyLabelSuccess: action.data,
  removeMyLabelLoading: false,
  removeMyLabelError: null,
  myLabelsData: state.myLabelsData.filter((label) => {
    return label.id !== action.data.id
  })
})

const receiveRemoveMyLabelError = (state = INITIAL_STATE, action) => ({
  ...state,
  removeMyLabelSuccess: null,
  removeMyLabelLoading: false,
  removeMyLabelError: action.error,
})

const requestPatientAccount = (state = INITIAL_STATE, action) => ({
  ...state,
  patientAccountData: null,
  patientAccountLoading: true,
  patientAccountError: null,
})

const receivePatientAccount = (state = INITIAL_STATE, action) => ({
  ...state,
  patientAccountData: action.data,
  patientAccountLoading: false,
})

const receivePatientAccountError = (state = INITIAL_STATE, action) => ({
  ...state,
  patientAccountLoading: false,
  patientAccountError: action.error,
})

const requestDisablePatientAccount = (state = INITIAL_STATE, action) => ({
  ...state,
  patientAccountData: null,
  patientAccountLoading: true,
  patientAccountError: null,
})

const receiveDisablePatientAccount = (state = INITIAL_STATE, action) => ({
  ...state,
  patientAccountData: action.data,
  patientAccountLoading: false,
})

const receiveDisablePatientAccountError = (state = INITIAL_STATE, action) => ({
  ...state,
  patientAccountLoading: false,
  patientAccountError: action.error,
})

export default createReducer(INITIAL_STATE, {
  [Types.RESET_USER_STATE]: resetUserState,
  [Types.RESTART_HELP_FLOW]: restartHelpFlow,
  [Types.RESTART_CREATE_PATIENT_FLOW]: restartCreatePatientFlow,
  [Types.RESTART_INVITE_PATIENT_FLOW]: restartInvitePatientFlow,
  [Types.RESTART_DELETE_PATIENT_FLOW]: restartDeletePatientFlow,
  [Types.RESTART_EDIT_PATIENT_FLOW]: restartEditPatientFlow,
  [Types.RESTART_EDIT_MY_ACCOUNT_FLOW]: restartEditMyAccountFlow,
  [Types.RESTART_CONFIRM_PASSWORD_FLOW]: restartConfirmPasswordFlow,
  [Types.RESTART_CREATE_MY_LABEL_FLOW]: restartCreateMyLabelFlow,
  [Types.RESTART_EDIT_MY_LABEL_FLOW]: restartEditMyLabelFlow,
  [Types.RESTART_REMOVE_MY_LABEL_FLOW]: restartRemoveMyLabelFlow,
  [Types.RESTART_INVITE_LOT_PATIENT_FLOW]: restartInviteLotPatientFlow,

  [Types.SET_USER_INFORMATION]: setUserInformation,

  [Types.REQUEST_PATIENTS]: requestPatients,
  [Types.RECEIVE_PATIENTS]: receivePatients,
  [Types.RECEIVE_PATIENTS_ERROR]: receivePatientsError,


  [Types.REQUEST_CREATE_PATIENT]: requestCreatePatient,
  [Types.RECEIVE_CREATE_PATIENT]: receiveCreatePatient,
  [Types.RECEIVE_CREATE_PATIENT_ERROR]: receiveCreatePatientError,

  [Types.REQUEST_EDIT_PATIENT]: requestEditPatient,
  [Types.RECEIVE_EDIT_PATIENT]: receiveEditPatient,
  [Types.RECEIVE_EDIT_PATIENT_ERROR]: receiveEditPatientError,

  [Types.REQUEST_INVITE_PATIENT]: requestInvitePatient,
  [Types.RECEIVE_INVITE_PATIENT]: receiveInvitePatient,
  [Types.RECEIVE_INVITE_PATIENT_ERROR]: receiveInvitePatientError,

  [Types.REQUEST_INVITE_LOT_PATIENT]: requestInviteLotPatient,
  [Types.RECEIVE_INVITE_LOT_PATIENT]: receiveInviteLotPatient,
  [Types.RECEIVE_INVITE_LOT_PATIENT_ERROR]: receiveInviteLotPatientError,

  [Types.REQUEST_DELETE_PATIENT]: requestDeletePatient,
  [Types.RECEIVE_DELETE_PATIENT]: receiveDeletePatient,
  [Types.RECEIVE_DELETE_PATIENT_ERROR]: receiveDeletePatientError,

  [Types.REQUEST_EDIT_MY_ACCOUNT]: requestEditMyAccount,
  [Types.RECEIVE_EDIT_MY_ACCOUNT]: receiveEditMyAccount,
  [Types.RECEIVE_EDIT_MY_ACCOUNT_ERROR]: receiveEditMyAccountError,

  [Types.REQUEST_CONFIRM_PASSWORD]: requestConfirmPassword,
  [Types.RECEIVE_CONFIRM_PASSWORD]: receiveConfirmPassword,
  [Types.RECEIVE_CONFIRM_PASSWORD_ERROR]: receiveConfirmPasswordError,

  [Types.REQUEST_SEARCH_CONTENT]: requestSearchContent,
  [Types.RECEIVE_SEARCH_CONTENT]: receiveSearchContent,
  [Types.RECEIVE_SEARCH_CONTENT_ERROR]: receiveSearchContentError,

  [Types.REQUEST_HELP]: requestHelp,
  [Types.RECEIVE_HELP]: receiveHelp,
  [Types.RECEIVE_HELP_ERROR]: receiveHelpError,

  [Types.REQUEST_BATTERIES_EXECUTED]: requestBatteriesExecuted,
  [Types.RECEIVE_BATTERIES_EXECUTED]: receiveBatteriesExecuted,
  [Types.RECEIVE_BATTERIES_EXECUTED_ERROR]: receiveBatteriesExecutedError,

  [Types.REQUEST_ALL_LABELS]: requestAllLabels,
  [Types.RECEIVE_ALL_LABELS]: receiveAllLabels,
  [Types.RECEIVE_ALL_LABELS_ERROR]: receiveAllLabelsError,

  [Types.REQUEST_MY_LABELS]: requestMyLabels,
  [Types.RECEIVE_MY_LABELS]: receiveMyLabels,
  [Types.RECEIVE_MY_LABELS_ERROR]: receiveMyLabelsError,

  [Types.REQUEST_CREATE_MY_LABEL]: requestCreateMyLabel,
  [Types.RECEIVE_CREATE_MY_LABEL]: receiveCreateMyLabel,
  [Types.RECEIVE_CREATE_MY_LABEL_ERROR]: receiveCreateMyLabelError,

  [Types.REQUEST_EDIT_MY_LABEL]: requestEditMyLabel,
  [Types.RECEIVE_EDIT_MY_LABEL]: receiveEditMyLabel,
  [Types.RECEIVE_EDIT_MY_LABEL_ERROR]: receiveEditMyLabelError,

  [Types.REQUEST_REMOVE_MY_LABEL]: requestRemoveMyLabel,
  [Types.RECEIVE_REMOVE_MY_LABEL]: receiveRemoveMyLabel,
  [Types.RECEIVE_REMOVE_MY_LABEL_ERROR]: receiveRemoveMyLabelError,

  [Types.REQUEST_PATIENT_ACCOUNT]: requestPatientAccount,
  [Types.RECEIVE_PATIENT_ACCOUNT]: receivePatientAccount,
  [Types.RECEIVE_PATIENT_ACCOUNT_ERROR]: receivePatientAccountError,

  [Types.REQUEST_DISABLE_PATIENT_ACCOUNT]: requestDisablePatientAccount,
  [Types.RECEIVE_DISABLE_PATIENT_ACCOUNT]: receiveDisablePatientAccount,
  [Types.RECEIVE_DISABLE_PATIENT_ACCOUNT_ERROR]: receiveDisablePatientAccountError,
  
})
