import axios from 'axios';
import { BIO_VALID_API, CREDENTIAL } from '../api';
import qs from 'qs';
import * as jwt from 'jsonwebtoken';
import {Creators} from './actions';

export const Async = {
  getTokenAccessBioValid: () => async dispatch => {
    dispatch(Creators.requestTokenAccess());
    try {
      const {data}  = await axios({
        method: 'POST',
        url: `${BIO_VALID_API}/token`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
          Authorization: `Basic ${CREDENTIAL}`
        },
        data: qs.stringify({ grant_type: 'client_credentials' })
      });
      dispatch(Creators.receiveTokenAccess(data));
    } catch (e) {
      dispatch(Creators.receiveTokenAccessError(e))
    }
  },
  getPINBioValid: ({cpf, token}) => async dispatch => {
    dispatch(Creators.requestPin());
    try {
      const { data } = await axios({
        method: 'POST',
        url: `${BIO_VALID_API}/biovalid/v1/token?cpf=${cpf}`,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        },
      });
      dispatch(Creators.receivePin(data));
    } catch (e) {
      dispatch(Creators.receivePinError(e));
    }
  },
  verifyBioValid: ({cpf, pin, token}) => async dispatch => {
    dispatch(Creators.requestVerify());
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${BIO_VALID_API}/biovalid/v1/token?cpf=${cpf}&token=${pin}`,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        },
      });
      const decoded = await jwt.decode(data);
      dispatch(Creators.receiveVerify(decoded));
    } catch (e) {
      dispatch(Creators.receiveVerifyError(e));
    }
  },
  resetPinState: () => dispatch => {
    dispatch(Creators.resetPinState())
  },
}
