import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  resetTestState: null,
  requestInitSession: null,
  receiveInitSession: ['data'],
  receiveInitSessionError: ['error'],

  requestNextTest: null,
  receiveNextTest: ['data'],
  receiveNextTestError: ['error'],

  requestIsPatient: null,
  receiveIsPatient: ['data'],
  receiveIsPatientError: ['error'],

  requestFinishedTests: null,
  receiveFinishedTests: ['data'],
  receiveFinishedTestsError: ['error'],

  requestSessionExist: null,
  receiveSessionExist: ['data'],
  receiveSessionExistError: ['error'],

  requestGetSession: null,
  receiveGetSession: ['data'],
  receiveGetSessionError: ['error'],

  requestGetBattery: null,
  receiveGetBattery: ['data'],
  receiveGetBatteryError: ['error'],
})
