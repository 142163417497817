import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import admin from './admin';
import user from './user';
import voluntaryTest from './voluntaryTest';
import patientTest from './patientTest';
import testsSessions from './testsSessions';
import bioValid from './bioValid';
import settings from '../redux/settings/reducer';
import menu from '../redux/menu/reducer';
import authUser from '../redux/auth/reducer';
import todoApp from '../redux/todo/reducer';
import chatApp from '../redux/chat/reducer';
import surveyListApp from '../redux/surveyList/reducer';
import surveyDetailApp from '../redux/surveyDetail/reducer';
import testSupport from './testSupport'
import batteryReport from './batteryReport';
import typeProcessing from './typeProcessing'
import battery from './battery'

const rootReducer = (history) => {
  const reducer = combineReducers({
    router: connectRouter(history),
    auth: auth.Reducers,
    admin: admin.Reducers,
    user: user.Reducers,
    voluntaryTest: voluntaryTest.Reducers,
    patientTest: patientTest.Reducers,
    testsSessions: testsSessions.Reducers,
    testSupport: testSupport.Reducers,
    bioValid: bioValid.Reducers,
    batteryReport: batteryReport.Reducers,
    typeProcessing: typeProcessing.Reducers,
    battery: battery.Reducers,
    settings,
    menu,todoApp, chatApp,surveyDetailApp, authUser, surveyListApp
  });
  return (state, action) => {
    if (action.type === 'RESET') {
      state = undefined;
    }
    return reducer(state, action);
  };
};

export default rootReducer;
