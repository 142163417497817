import axios from "axios";

const client = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

const reqSuccess = async (config) => {
  const token = localStorage.getItem('token');
  const authHeaders = {
    Authorization: `Bearer ${token}`,
  };

  return {
    ...config,
    headers: {
      ...config.headers,
      ...authHeaders,
    },
  };
};

const responseSuccess = (res) => res;

const responseError = async (error) => {
  if (error.message.match(/Network Error/)) {
    const msg = 'Falha na conexão com o servidor.';
    return Promise.reject(new Error(msg));
  }
  if (error.response && error.response.data) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      data: error.response.data,
      status: error.response.status,
    });
  }
  return Promise.reject(error);
};

client.interceptors.request.use(reqSuccess, undefined);
client.interceptors.response.use(responseSuccess, responseError);

/**
 * @see {@link https://github.com/axios/axios#axiosrequestconfig}
 */
const request = (opts) => client.request(opts);

export { client, request };
