import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  resetState: null,
  requestSupport: null,
  receiveSupport: ['data'],
  receiveSupportError: ['error'],

  requestVerifyToken: null,
  receiveVerifyToken: ['data'],
  receiveVerifyTokenError: ['error'],

  requestVerifyRecaptcha: null,
  receiveVerifyRecaptcha: ['data'],
  receiveVerifyRecaptchaError: ['error'],
})
