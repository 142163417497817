import { request } from '../../config/Http';
import { API_URL } from '../api';
import { Creators } from './actions';

export const Async = {
    listAllTypeProcessing: () => async dispatch => {
        dispatch(Creators.requestTypeProcessing())
        try {
            const { data } = await request({
                method: 'GET',
                url: `${API_URL}/type-processing/list-processing`
            });
            dispatch(Creators.receiveTypeProcessing(data));
        } catch (e) {
            dispatch(Creators.receiveTypeProcessingError(e));
        }
    },
    createTypeProcessing: ({title, description, url}) => async dispatch => {
        dispatch(Creators.requestCreateTypeProcessing())
        try {
            const {data} = await request({
                method: 'POST',
                url: `${API_URL}/type-processing/create`,
                data: {
                    title,
                    description,
                    url
                }
            })
            dispatch(Creators.receiveCreateTypeProcessing(data))
        } catch (e) {
            dispatch(Creators.receiveCreateTypeProcessingError(e))
        }
    },
    editTypeProcessing: ({id, title, description, url, active}) => async dispatch => {
        dispatch(Creators.requestEditTypeProcessing())
        try {
            const {data} = await request({
                method: 'PUT',
                url: `${API_URL}/type-processing/edit`,
                data: {
                    id,
                    title,
                    description,
                    url,
                    active
                }
            })
            dispatch(Creators.receiveEditTypeProcessing(data))
        } catch (e) {
            dispatch(Creators.receiveEditTypeProcessingError(e))
        }
    },
    removeTypeProcessing: ({id}) => async dispatch => {
        dispatch(Creators.requestRemoveTypeProcessing())
        try {
            const {data} = await request({
                method: 'DELETE',
                url: `${API_URL}/type-processing/remove`,
                data: {
                    id,
                }
            })
            dispatch(Creators.receiveRemoveTypeProcessing(data))
        } catch (e) {
            dispatch(Creators.receiveRemoveTypeProcessingError(e))
        }
    }
}
