import { createReducer } from 'reduxsauce';
import {Types} from './actions';

export const INITIAL_STATE = {

  pinData: null,
  pinError: null,
  pinLoading: false,

  tokenAccessData: null,
  tokenAccessError: null,
  tokenAccessLoading: false,

  verifyBioValidData: null,
  verifyBioValidLoading: false,
  verifyBioValidError: null
}

// reset

const resetPinState = () => ({
  pinData: null,
  pinLoading: false,
  pinDataError: null,
  tokenAccessData: null,
  tokenAccessError: null,
  tokenAccessLoading: false,
})

const requestTokenAccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    tokenAccessData: null,
    tokenAccessError: null,
    tokenAccessLoading: true,
  }
}
const receiveTokenAccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    tokenAccessData: action.data.access_token,
    tokenAccessError: null,
    tokenAccessLoading: false,
  }
}
const receiveTokenAccessError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    tokenAccessData: null,
    tokenAccessError: action.error,
    tokenAccessLoading: false,
  }
}

const requestPin = (state = INITIAL_STATE) => ({
  ...state,
  pinData: null,
  pinLoading: true,
  pinDataError: null,
})

const receivePin = (state = INITIAL_STATE, action) => ({
  ...state,
  pinData: action.data,
  pinLoading: false,
  pinDataError: null
})

const receivePinError = (state = INITIAL_STATE, action) => ({
  ...state,
  pinData: null,
  pinLoading: false,
  pinDataError: action.error
})
const requestVerify = (state = INITIAL_STATE) => ({
  ...state,
  verifyBioValidData: null,
  verifyBioValidLoading: true,
  verifyBioValidError: null
})

const receiveVerify = (state = INITIAL_STATE, action) => ({
  ...state,
  verifyBioValidData: action.data,
  verifyBioValidLoading: false,
  verifyBioValidError: null
})

const receiveVerifyError = (state = INITIAL_STATE, action) => ({
  ...state,
  verifyBioValidData: null,
  verifyBioValidLoading: false,
  verifyBioValidError: action.error
})

export default createReducer(INITIAL_STATE, {
  [Types.RESET_PIN_STATE]: resetPinState,

  [Types.REQUEST_TOKEN_ACCESS]: requestTokenAccess,
  [Types.RECEIVE_TOKEN_ACCESS]: receiveTokenAccess,
  [Types.RECEIVE_TOKEN_ACCESS_ERROR]: receiveTokenAccessError,

  [Types.REQUEST_PIN]: requestPin,
  [Types.RECEIVE_PIN]: receivePin,
  [Types.RECEIVE_PIN_ERROR]: receivePinError,

  [Types.REQUEST_VERIFY]: requestVerify,
  [Types.RECEIVE_VERIFY]: receiveVerify,
  [Types.RECEIVE_VERIFY_ERROR]: receiveVerifyError

})
