import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  restartHelpFlow: null,
  restartCreatePatientFlow: null,
  restartInvitePatientFlow: null,
  restartDeletePatientFlow: null,
  restartEditPatientFlow: null,
  restartEditMyAccountFlow: null,
  restartConfirmPasswordFlow: null,
  resetUserState: null,
  restartCreateMyLabelFlow: null,
  restartEditMyLabelFlow: null,
  restartRemoveMyLabelFlow: null,
  restartInviteLotPatientFlow: null,

  setUserInformation: ['data'],

  requestEditMyAccount: null,
  receiveEditMyAccount: ['data'],
  receiveEditMyAccountError: ['error'],

  requestConfirmPassword: null,
  receiveConfirmPassword: ['data'],
  receiveConfirmPasswordError: ['error'],

  requestPatients: null,
  receivePatients: ['data'],
  receivePatientsError: ['error'],

  requestCreatePatient: null,
  receiveCreatePatient: ['data'],
  receiveCreatePatientError: ['error'],

  requestEditPatient: null,
  receiveEditPatient: ['data'],
  receiveEditPatientError: ['error'],

  requestInvitePatient: null,
  receiveInvitePatient: ['data'],
  receiveInvitePatientError: ['error'],

  requestDeletePatient: null,
  receiveDeletePatient: ['data'],
  receiveDeletePatientError: ['error'],

  requestSearchContent: null,
  receiveSearchContent: ['data'],
  receiveSearchContentError: ['error'],

  requestHelp: null,
  receiveHelp: null,
  receiveHelpError: ['error'],


  requestBatteriesExecuted: null,
  receiveBatteriesExecuted: ['data'],
  receiveBatteriesExecutedError: ['error'],

  requestAllLabels: null,
  receiveAllLabels: ['data'],
  receiveAllLabelsError: ['error'],

  requestMyLabels: null,
  receiveMyLabels: ['data'],
  receiveMyLabelsError: ['error'],

  requestCreateMyLabel: null,
  receiveCreateMyLabel: ['data'],
  receiveCreateMyLabelError: ['error'],

  requestEditMyLabel: null,
  receiveEditMyLabel: ['data'],
  receiveEditMyLabelError: ['error'],

  requestRemoveMyLabel: null,
  receiveRemoveMyLabel: ['data'],
  receiveRemoveMyLabelError: ['error'],

  requestInviteLotPatient: null,
  receiveInviteLotPatient: ['data'],
  receiveInviteLotPatientError: ['error'],

  requestPatientAccount: null,
  receivePatientAccount: ['data'],
  receivePatientAccountError: ['error'],

  requestDisablePatientAccount: null,
  receiveDisablePatientAccount: ['data'],
  receiveDisablePatientAccountError: ['error'],

})
