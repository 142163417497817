import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({

  requestPin: null,
  receivePin: ['data'],
  receivePinError: ['error'],

  requestTokenAccess: null,
  receiveTokenAccess: ['data'],
  receiveTokenAccessError: ['error'],

  requestVerify: null,
  receiveVerify: ['data'],
  receiveVerifyError: ['error'],

  resetPinState: null,


})
