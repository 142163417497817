import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  requestBatteryData: null,
  receiveBatteryData: ['data'],
  receiveBatteryDataError: ['error'],

  requestReportData: null,
  receiveReportData: ['data'],
  receiveReportDataError: ['error']
})
