import { request } from '../../config/Http';
import { API_URL } from '../api';
import { Creators } from './actions';

export const Async = {
    getBatteryData: (sessionId) => async dispatch => {
        dispatch(Creators.requestBatteryData())
        try {
            const { data } = await request({
                method: 'GET',
                url: `${API_URL}/session/get-executed-session?sessionId=${sessionId}`
            })
            dispatch(Creators.receiveBatteryData(data))
        } catch (e) {
            dispatch(Creators.receiveBatteryDataError(e))
        }
    },
    getReport: (sessionId) => async dispatch => {
        dispatch(Creators.requestReportData())
        try {
            const { data } = await request({
                method: 'GET',
                url: `${API_URL}/report/get-by-session/${sessionId}`
            })
            dispatch(Creators.receiveReportData(data))
        } catch (e) {
            dispatch(Creators.receiveReportDataError(e))
        }
    }
}
