import { request } from '../../config/Http';
import { API_URL } from '../api';
import {Creators} from './actions';

export const Async = {
  isPatient: ({ testToken }) => async dispatch => {
    dispatch(Creators.requestIsPatient())
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/user/is-user-patient-valid?testToken=${testToken}`
      })
      dispatch(Creators.receiveIsPatient(data))
    } catch (e) {
      dispatch(Creators.receiveIsPatientError(e))
    }

  },
  getBattery: ({publicLink, isPublic, testToken, locale}) => async dispatch => {
    dispatch(Creators.requestGetBattery())
    try {
      const {data} = await request({
        method: 'GET',
        url: `${API_URL}/battery/get-battery`,
        params: {
          publicLink,
          isPublic,
          testToken,
          lng: locale
        }
      })
      dispatch(Creators.receiveGetBattery(data))
    } catch (e) {
      dispatch(Creators.receiveGetBatteryError(e))
    }
  },
  getSession: ({sessionId}) => async dispatch => {
    dispatch(Creators.requestGetSession())
    try {
      const {data} = await request({
        method: 'GET',
        url: `${API_URL}/session?sessionId=${sessionId}`,
      })
      dispatch(Creators.receiveGetSession(data))
    } catch (e) {
      dispatch(Creators.receiveGetSessionError(e))
    }
  },
  getSessionExist: ({testToken}) => async dispatch => {
    dispatch(Creators.requestSessionExist());
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/session/session-exist?testToken=${testToken}`
      });
      dispatch(Creators.receiveSessionExist(data));
    } catch (e) {
      dispatch(Creators.receiveSessionExistError(e));
    }
  },
  initSession: ({ testToken, payloadBioValid, language }) => async dispatch => {
    dispatch(Creators.requestInitSession())
    try {
      const { data } = await request({
        method: 'POST',
        url: `${API_URL}/session/create`,
        data: {
          testToken,
          payloadBioValid,
          language
        }

      })
      dispatch(Creators.receiveInitSession(data))
    } catch (e) {
      dispatch(Creators.receiveInitSessionError(e))
    }
  },
  getNextTest: ({ id }) => async dispatch => {
    dispatch(Creators.requestNextTest())
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/session-test/next-test?id=${id}`

      })
      dispatch(Creators.receiveNextTest(data))
    } catch (e) {
      dispatch(Creators.receiveNextTestError(e))
    }
  },
  getExecutedTests: ({ sessionId }) => async dispatch => {
    dispatch(Creators.requestFinishedTests());
    try {
      const {data} = await request({
        method: 'GET',
        url: `${API_URL}/session/get-executed-tests?sessionId=${sessionId}`,
      });
      dispatch(Creators.receiveFinishedTests(data))
    } catch (e) {
      dispatch(Creators.receiveFinishedTestsError(e));
    }
  }

}
