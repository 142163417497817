import { request } from '../../config/Http';
import { API_URL } from '../api';
import { Creators } from './actions';

export const Async = {
  initPublicSession:
    ({ publicLink, payloadBioValid, language }) =>
    async (dispatch) => {
      dispatch(Creators.requestInitSession());
      try {
        const { data } = await request({
          method: 'POST',
          url: `${API_URL}/session/create-public-session`,
          data: {
            publicLink,
            payloadBioValid,
            language,
          },
        });
        dispatch(Creators.receiveInitSession(data));
      } catch (e) {
        dispatch(Creators.receiveInitSessionError(e));
      }
    },
  sendBatteryFeedback:
    ({ sessionId, name, email, comment }) =>
    async (dispatch) => {
      dispatch(Creators.requestSendBatteryFeedback());
      try {
        const { data } = await request({
          method: 'POST',
          url: `${API_URL}/battery/feedback`,
          data: {
            sessionId,
            name,
            email,
            comment,
          },
        });
        dispatch(Creators.receiveSendBatteryFeedback(data));
      } catch (e) {
        dispatch(Creators.receiveSendBatteryFeedbackError(e));
      }
    },
  hasFeedback:
    ({ sessionId }) =>
    async (dispatch) => {
      dispatch(Creators.requestHasFeedback());
      try {
        const { data } = await request({
          method: 'GET',
          url: `${API_URL}/battery/has-feedback?sessionId=${sessionId}`,
        });
        dispatch(Creators.receiveHasFeedback(data));
      } catch (e) {
        dispatch(Creators.receiveHasFeedbackError(e));
      }
    },
  getPublicSession:
    ({ sessionId }) =>
    async (dispatch) => {
      dispatch(Creators.requestGetPublicSession());
      try {
        const { data } = await request({
          method: 'GET',
          url: `${API_URL}/session?sessionId=${sessionId}`,
        });
        dispatch(Creators.receiveGetPublicSession(data));
      } catch (e) {
        dispatch(Creators.receiveGetPublicSessionError(e));
      }
    },
  initSession:
    ({ testToken, payloadBioValid }) =>
    async (dispatch) => {
      dispatch(Creators.requestInitSession());
      try {
        const { data } = await request({
          method: 'POST',
          url: `${API_URL}/session/create`,
          data: {
            testToken,
            payloadBioValid,
          },
        });
        dispatch(Creators.receiveInitSession(data));
      } catch (e) {
        dispatch(Creators.receiveInitSessionError(e));
      }
    },
  getSessionExist:
    ({ testToken }) =>
    async (dispatch) => {
      dispatch(Creators.requestSessionExist());
      try {
        const { data } = await request({
          method: 'GET',
          url: `${API_URL}/session/session-exist?testToken=${testToken}`,
        });
        dispatch(Creators.receiveSessionExist(data));
      } catch (e) {
        dispatch(Creators.receiveSessionExistError(e));
      }
    },
  getNextTest:
    ({ id }) =>
    async (dispatch) => {
      dispatch(Creators.requestNextTest());
      try {
        const { data } = await request({
          method: 'GET',
          url: `${API_URL}/session-test/next-test?id=${id}`,
        });
        dispatch(Creators.receiveNextTest(data));
      } catch (e) {
        dispatch(Creators.receiveNextTestError(e));
      }
    },
  sendReport:
    ({ sessionId, phoneNumber, email, schoolingDegree, gender, birthdate }) =>
    async (dispatch) => {
      dispatch(Creators.requestSendReport());
      try {
        const { data } = await request({
          method: 'POST',
          url: `${API_URL}/volunteer/send-report`,
          data: {
            sessionId,
            phoneNumber,
            email,
            schoolingDegree,
            gender,
            birthdate,
          },
        });
        dispatch(Creators.receiveSendReport(data));
      } catch (e) {
        dispatch(Creators.receiveSendReportError(e));
      }
    },
  getExecutedTests:
    ({ sessionId }) =>
    async (dispatch) => {
      dispatch(Creators.requestFinishedTests());
      try {
        const { data } = await request({
          method: 'GET',
          url: `${API_URL}/session/get-executed-tests?sessionId=${sessionId}`,
        });
        dispatch(Creators.receiveFinishedTests(data));
      } catch (e) {
        dispatch(Creators.receiveFinishedTestsError(e));
      }
    },
  sendBatteryLinkToEmail:
    ({ sessionId, email }) =>
    async (dispatch) => {
      dispatch(Creators.requestSendBatteryLinkToEmail());
      try {
        await request({
          method: 'POST',
          url: `${API_URL}/session/send-link-to-email`,
          data: {
            sessionId,
            email,
          },
        });
        dispatch(Creators.receiveSendBatteryLinkToEmail());
      } catch (e) {
        dispatch(Creators.receiveSendBatteryLinkToEmailError(e));
      }
    },
};
