import { createReducer } from 'reduxsauce';
import {Types} from './actions'

const INITIAL_STATE = {
  sessionsData: null,
  sessionsLoading: false,
  sessionsError: null,
}

const requestSessions = (state = INITIAL_STATE) => ({
  ...state,
  sessionsData: null,
  sessionsLoading: true,
  sessionsError: null,
})

const receiveSessions = (state = INITIAL_STATE, action) => ({
  ...state,
  sessionsData: action.data,
  sessionsLoading: false,
  sessionsError: null,
})

const receiveSessionsError = (state = INITIAL_STATE, action) => ({
  ...state,
  sessionsData: null,
  sessionsLoading: false,
  sessionsError: null,
})

export default createReducer(INITIAL_STATE, {
  [Types.REQUEST_SESSIONS]: requestSessions,
  [Types.RECEIVE_SESSIONS]: receiveSessions,
  [Types.RECEIVE_SESSIONS_ERROR]: receiveSessionsError,
})
