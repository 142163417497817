import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  resetAuthState: null,
  resetSendNewEmailState: null,

  requestAuth: null,
  receiveAuth: ['token', 'user'],
  receiveAuthError: ['authError'],

  requestAuthForResetPassword: null,
  receiveAuthForResetPassword: ['token'],
  receiveAuthForResetPasswordError: ['authError'],


  requestLogin: null,
  receiveLogin: ['token', 'user', 'isNotVerifiedToken'],
  receiveLoginError: ['loginError'],


  requestRegister: null,
  receiveRegister: ['data'],
  receiveRegisterError: ['registerError'],

  requestForgotPassword: null,
  receiveForgotPassword: ['data'],
  receiveForgotPasswordError: ['forgotPasswordError'],

  requestResetPassword: null,
  receiveResetPassword: ['data'],
  receiveResetPasswordError: ['resetPasswordError'],

  resetRegisterState: null,

  requestSendNewEmailVerified: null,
  receiveSendNewEmailVerified: ['data'],
  receiveSendNewEmailVerifiedError: ['sendNewEmailVerifiedError'],

  requestVerifyEmail: null,
  receiveVerifyEmail: ['data'],
  receiveVerifyEmailError: ['verifyEmailError'],

  requestGetTestData: null,
  receiveGetTestData: ['data'],
  receiveGetTestDataError: ['receiveGetTestDataError'],

  requestIsPreUser: null,
  receiveIsPreUser: ['data'],
  receiveIsPreUserError: ['error'],
})
