import { createReducer } from 'reduxsauce';
import { Types } from './actions';

const INITIAL_STATE = {
  sessionData: null,
  sessionLoading: false,
  sessionError: null,

  nextTestData: null,
  nextTestLoading: false,
  nextTestError: null,

  sendReportSuccess: null,
  sendReportLoading: false,
  sendReportError: null,

  sessionExistSuccess: null,
  sessionExistLoading: false,
  sessionExistError: null,

  finishedTests: null,
  finishedTestsLoading: false,
  finishedTestsError: null,

  sendBatteryFeedbackSuccess: null,
  sendBatteryFeedbackLoading: false,
  sendBatteryFeedbackError: null,

  hasFeedbackSuccess: null,
  hasFeedbackLoading: false,
  hasFeedbackError: false,

  sendBatteryLinkSuccess: false,
  sendBatteryLinkLoading: false,
  sendBatteryLinkError: null,
};

const resetTestState = () => ({
  sessionData: null,
  sessionLoading: false,
  sessionError: null,

  nextTestData: null,
  nextTestLoading: false,
  nextTestError: null,

  isPatientData: null,
  isPatientLoading: false,
  isPatientError: null,

  sessionExistSuccess: null,
  sessionExistLoading: false,
  sessionExistError: null,

  finishedTests: null,
  finishedTestsError: null,
  finishedTestsLoading: false,

  sendBatteryFeedbackSuccess: null,
  sendBatteryFeedbackLoading: false,
  sendBatteryFeedbackError: null,

  hasFeedbackSuccess: null,
  hasFeedbackLoading: false,
  hasFeedbackError: null,

  getPublicSessionSuccess: null,
  getPublicSessionLoading: false,
  getPublicSessionError: null,
});
// Init Session
const requestInitSession = (state = INITIAL_STATE) => ({
  ...state,
  sessionData: null,
  sessionLoading: true,
  sessionError: null,
});

const receiveInitSession = (state = INITIAL_STATE, action) => ({
  ...state,
  sessionData: action.data,
  sessionLoading: false,
  sessionError: null,
});

const receiveInitSessionError = (state = INITIAL_STATE, action) => ({
  ...state,
  sessionData: null,
  sessionLoading: false,
  sessionError: action.error,
});

const requestSessionExist = (state = INITIAL_STATE) => ({
  ...state,
  sessionExistSuccess: null,
  sessionExistLoading: true,
  sessionExistError: null,
});

const receiveSessionExist = (state = INITIAL_STATE, action) => ({
  ...state,
  sessionExistSuccess: action.data,
  sessionExistLoading: false,
  sessionExistError: null,
});

const receiveSessionExistError = (state = INITIAL_STATE, action) => ({
  ...state,
  sessionExistSuccess: null,
  sessionExistLoading: false,
  sessionExistError: action.error,
});

// Next Test
const requestNextTest = (state = INITIAL_STATE) => ({
  ...state,
  nextTestData: null,
  nextTestLoading: true,
  nextTestError: null,
});

const receiveNextTest = (state = INITIAL_STATE, action) => ({
  ...state,
  nextTestData: action.data,
  nextTestLoading: false,
  nextTestError: null,
});

const receiveNextTestError = (state = INITIAL_STATE, action) => ({
  ...state,
  nextTestData: null,
  nextTestLoading: false,
  nextTestError: action.error,
});

// Send-Report

const requestSendReport = (state = INITIAL_STATE) => ({
  ...state,
  sendReportSuccess: null,
  sendReportLoading: true,
  sendReportError: null,
});

const receiveSendReport = (state = INITIAL_STATE, action) => ({
  ...state,
  sendReportSuccess: true,
  sendReportLoading: false,
  sendReportError: null,
});

const receiveSendReportError = (state = INITIAL_STATE, action) => ({
  ...state,
  sendReportSuccess: null,
  sendReportLoading: false,
  sendReportError: action.error,
});

const requestFinishedTests = (state = INITIAL_STATE) => ({
  ...state,
  finishedTests: null,
  finishedTestsLoading: true,
  finishedTestsError: null,
});

const receiveFinishedTests = (state = INITIAL_STATE, action) => ({
  ...state,
  finishedTests: action.data,
  finishedTestsLoading: false,
  finishedTestsError: null,
});

const receiveFinishedTestsError = (state = INITIAL_STATE, action) => ({
  ...state,
  finishedTests: null,
  finishedTestsLoading: false,
  finishedTestsError: action.error,
});

const requestSendBatteryFeedback = (state = INITIAL_STATE) => ({
  ...state,
  sendBatteryFeedbackSuccess: null,
  sendBatteryFeedbackLoading: true,
  sendBatteryFeedbackError: null,
});

const receiveSendBatteryFeedback = (state = INITIAL_STATE, action) => ({
  ...state,
  sendBatteryFeedbackSuccess: action.data,
  sendBatteryFeedbackLoading: false,
  sendBatteryFeedbackError: null,
});

const receiveSendBatteryFeedbackError = (state = INITIAL_STATE, action) => ({
  ...state,
  sendBatteryFeedbackSuccess: null,
  sendBatteryFeedbackLoading: false,
  sendBatteryFeedbackError: action.error,
});

const requestHasFeedback = (state = INITIAL_STATE) => ({
  ...state,
  hasFeedbackSuccess: null,
  hasFeedbackLoading: true,
  hasFeedbackError: null,
});

const receiveHasFeedback = (state = INITIAL_STATE, action) => ({
  ...state,
  hasFeedbackSuccess: action.data,
  hasFeedbackLoading: false,
  hasFeedbackError: null,
});

const receiveHasFeedbackError = (state = INITIAL_STATE, action) => ({
  ...state,
  hasFeedbackSuccess: null,
  hasFeedbackLoading: false,
  hasFeedbackError: action.error,
});

const requestGetPublicSession = (state = INITIAL_STATE) => ({
  ...state,
  getPublicSessionSuccess: null,
  getPublicSessionLoading: true,
  getPublicSessionError: null,
});

const receiveGetPublicSession = (state = INITIAL_STATE, action) => ({
  ...state,
  getPublicSessionSuccess: action.data,
  getPublicSessionLoading: false,
  getPublicSessionError: null,
});

const receiveGetPublicSessionError = (state = INITIAL_STATE, action) => ({
  ...state,
  getPublicSessionSuccess: null,
  getPublicSessionLoading: false,
  getPublicSessionError: action.error,
});

const requestSendBatteryLinkToEmail = (state = INITIAL_STATE) => ({
  ...state,
  sendBatteryLinkSuccess: false,
  sendBatteryLinkLoading: true,
  sendBatteryLinkError: null,
});

const receiveSendBatteryLinkToEmail = (state = INITIAL_STATE, action) => ({
  ...state,
  sendBatteryLinkSuccess: true,
  sendBatteryLinkLoading: false,
  sendBatteryLinkError: null,
});

const receiveSendBatteryLinkToEmailError = (state = INITIAL_STATE, action) => ({
  ...state,
  sendBatteryLinkSuccess: false,
  sendBatteryLinkLoading: false,
  sendBatteryLinkError: action.error,
});

const resetSendBatteryLinkState = (state = INITIAL_STATE) => ({
  ...state,
  sendBatteryLinkSuccess: false,
  sendBatteryLinkLoading: false,
  sendBatteryLinkError: null,
});

export default createReducer(INITIAL_STATE, {
  [Types.RESET_TEST_STATE]: resetTestState,
  [Types.REQUEST_INIT_SESSION]: requestInitSession,
  [Types.RECEIVE_INIT_SESSION]: receiveInitSession,
  [Types.RECEIVE_INIT_SESSION_ERROR]: receiveInitSessionError,

  [Types.REQUEST_SEND_REPORT]: requestNextTest,
  [Types.RECEIVE_NEXT_TEST]: receiveNextTest,
  [Types.RECEIVE_NEXT_TEST_ERROR]: receiveNextTestError,

  [Types.REQUEST_SEND_REPORT]: requestSendReport,
  [Types.RECEIVE_SEND_REPORT]: receiveSendReport,
  [Types.RECEIVE_SEND_REPORT_ERROR]: receiveSendReportError,

  [Types.REQUEST_FINISHED_TESTS]: requestFinishedTests,
  [Types.RECEIVE_FINISHED_TESTS]: receiveFinishedTests,
  [Types.RECEIVE_FINISHED_TESTS_ERROR]: receiveFinishedTestsError,

  [Types.REQUEST_SESSION_EXIST]: requestSessionExist,
  [Types.RECEIVE_SESSION_EXIST]: receiveSessionExist,
  [Types.RECEIVE_SESSION_EXIST_ERROR]: receiveSessionExistError,

  [Types.REQUEST_SEND_BATTERY_FEEDBACK]: requestSendBatteryFeedback,
  [Types.RECEIVE_SEND_BATTERY_FEEDBACK]: receiveSendBatteryFeedback,
  [Types.RECEIVE_SEND_BATTERY_FEEDBACK_ERROR]: receiveSendBatteryFeedbackError,

  [Types.REQUEST_HAS_FEEDBACK]: requestHasFeedback,
  [Types.RECEIVE_HAS_FEEDBACK]: receiveHasFeedback,
  [Types.RECEIVE_HAS_FEEDBACK_ERROR]: receiveHasFeedbackError,

  [Types.REQUEST_GET_PUBLIC_SESSION]: requestGetPublicSession,
  [Types.RECEIVE_GET_PUBLIC_SESSION]: receiveGetPublicSession,
  [Types.RECEIVE_GET_PUBLIC_SESSION_ERROR]: receiveGetPublicSessionError,

  [Types.REQUEST_SEND_BATTERY_LINK_TO_EMAIL]: requestSendBatteryLinkToEmail,
  [Types.RECEIVE_SEND_BATTERY_LINK_TO_EMAIL]: receiveSendBatteryLinkToEmail,
  [Types.RECEIVE_SEND_BATTERY_LINK_TO_EMAIL_ERROR]:
    receiveSendBatteryLinkToEmailError,
  [Types.RESET_SEND_BATTERY_LINK_STATE]: resetSendBatteryLinkState,
});
