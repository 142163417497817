import { request } from '../../config/Http';
import { API_URL } from '../api';
import { Creators } from './actions';

export const Async = {
  editMyAccount: ({ id, name, cpf, city, areaOfExpertise, identificationNumber, gender, phoneNumber, newPassword, file }) => async dispatch => {
    dispatch(Creators.requestEditMyAccount())
    try {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('name', name);
      formData.append('cpf', cpf);
      formData.append('city', city);
      formData.append('areaOfExpertise', areaOfExpertise);
      formData.append('identificationNumber', identificationNumber);
      formData.append('gender', gender);
      formData.append('phoneNumber', phoneNumber);
      formData.append('newPassword', newPassword);
      if(file) {
        formData.append('file', file, file.name);
      }
      const { data } = await request({
        method: 'PUT',
        url: `${API_URL}/user/edit`,
        data: formData
      })
      dispatch(Creators.receiveEditMyAccount(data))
    } catch (e) {
      dispatch(Creators.receiveEditMyAccountError(e))
    }
  },
  confirmPassword: ({ id, password }) => async dispatch => {
    dispatch(Creators.requestConfirmPassword())
    try {
      const { data } = await request({
        method: 'POST',
        url: `${API_URL}/user/confirm-password`,
        data: {
          id,
          password
        }
      })
      dispatch(Creators.receiveConfirmPassword(data))
    } catch (e) {
      dispatch(Creators.receiveConfirmPasswordError(e))
    }
  },
  getMyPatients: () => async dispatch => {
    dispatch(Creators.requestPatients())
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/patient/list-patients`,
      })
      dispatch(Creators.receivePatients(data))
    } catch (e) {
      dispatch(Creators.receivePatientsError(e))
    }
  },
  deletePatient: ({ id }) => async dispatch => {
    dispatch(Creators.requestDeletePatient())
    try {
      const { data } = await request({
        method: 'DELETE',
        url: `${API_URL}/patient/remove`,
        data: {
          idPatient: id
        }
      })
      dispatch(Creators.receiveDeletePatient(data))
    } catch (e) {
      dispatch(Creators.receiveDeletePatientError(e))
    }
  },
  createPatient: ({ code, gender, schoolingDegree, birthdate, email, phone, labelsSelected }) => async dispatch => {
    dispatch(Creators.requestCreatePatient())
    try {
      const { data } = await request({
        method: 'POST',
        url: `${API_URL}/patient/create`,
        data: {
          code,
          gender,
          schoolingDegree,
          birthdate,
          email,
          phone,
          labelsSelected
        }
      })
      dispatch(Creators.receiveCreatePatient(data))
    } catch (e) {
      dispatch(Creators.receiveCreatePatientError(e))
    }
  },
  editPatient: ({ code, gender, schoolingDegree, birthdate, email, phone, idPatient, labelsSelected, labelsForRemove }) => async dispatch => {
    dispatch(Creators.requestEditPatient())
    try {
      const { data } = await request({
        method: 'PUT',
        url: `${API_URL}/patient/edit/${idPatient}`,
        data: {
          code,
          gender,
          schoolingDegree,
          birthdate,
          email,
          phone,
          labelsSelected,
          labelsForRemove
        }
      })
      dispatch(Creators.receiveEditPatient(data))
    } catch (e) {
      dispatch(Creators.receiveEditPatientError(e))
    }
  },
  invitePatient: ({ userId, patientId, batteryId, sharedType }) => async dispatch => {
    dispatch(Creators.requestInvitePatient())
    try {
      const { data } = await request({
        method: 'POST',
        url: `${API_URL}/user/invite-patient`,
        data: {
          userId,
          patientId,
          batteryId,
          sharedType
        }
      })
      dispatch(Creators.receiveInvitePatient(data))
    } catch (e) {
      dispatch(Creators.receiveInvitePatientError(e))
    }
  },
  inviteLotPatients: ({patients, batteryId}) => async dispatch => {
    dispatch(Creators.requestInviteLotPatient())
    try {
      const { data } = await request({
        method: 'POST',
        url: `${API_URL}/user/invite-lot-patients`,
        data: {
          patients,
          batteryId
        }
      })
      dispatch(Creators.receiveInviteLotPatient(data))
    } catch (e) {
      dispatch(Creators.receiveInviteLotPatientError(e))
    }
  },
  searchContent: ({ userId, searchString }) => async dispatch => {
    dispatch(Creators.requestSearchContent())
    try {
      const { data } = await request({
        method: 'POST',
        url: `${API_URL}/user/search-content`,
        data: {
          userId,
          searchString,
        }
      })
      dispatch(Creators.receiveSearchContent(data))
    } catch (e) {
      dispatch(Creators.receiveSearchContentError(e))
    }
  },
  askForHelp: ({ type, subject, title, tests, batteries, message, id }) => async dispatch => {
    dispatch(Creators.requestHelp())
    try {
      await request({
        method: 'POST',
        url: `${API_URL}/user/help`,
        data: {
          id,
          type,
          subject,
          title,
          tests,
          batteries,
          message
        }
      })
      dispatch(Creators.receiveHelp())
    } catch (e) {
      dispatch(Creators.receiveHelpError(e))
    }
  },
  getBatteriesExecuted: ({patientId}) => async dispatch => {
    dispatch(Creators.requestBatteriesExecuted());
    try {
      const {data} = await request({
        method: 'GET',
        url: `${API_URL}/patient/list-batteries-executed?patientId=${patientId}`
      });
      dispatch(Creators.receiveBatteriesExecuted(data));
    } catch (e) {
      dispatch(Creators.receiveBatteriesExecutedError(e));
    }
  },
  getAllListLabels: () => async dispatch => {
    dispatch(Creators.requestAllLabels())
    try {
      const {data} = await request({
        method: 'GET',
        url: `${API_URL}/label/list-all-labels`
      })
      dispatch(Creators.receiveAllLabels(data))
    } catch (e) {
      dispatch(Creators.receiveAllLabelsError(e))
    }
  },
  getMyListLabels: () => async dispatch => {
    dispatch(Creators.requestMyLabels())
    try {
      const {data} = await request({
        method: 'GET',
        url: `${API_URL}/label/list-labels-user`
      })
      dispatch(Creators.receiveMyLabels(data))
    } catch (e) {
      dispatch(Creators.receiveMyLabelsError(e))
    }
  },
  createMyLabel: ({name, description}) => async dispatch => {
    dispatch(Creators.requestCreateMyLabel())
    try {
      const {data} = await request({
        method: 'POST',
        url: `${API_URL}/label/create-label`,
        data: {
          name,
          description
        }
      })
      dispatch(Creators.receiveCreateMyLabel(data))
    } catch (e) {
      dispatch(Creators.receiveCreateMyLabelError(e))
    }
  },
  editMyLabel: ({id, name, description}) => async dispatch => {
    dispatch(Creators.requestEditMyLabel())
    try {
      const {data} = await request({
        method: 'PUT',
        url: `${API_URL}/label/edit-label`,
        data: {
          id,
          name,
          description
        }
      })
      dispatch(Creators.receiveEditMyLabel(data))
    } catch (e) {
      dispatch(Creators.receiveEditMyLabelError(e))
    }
  },
  removeMyLabel: ({id}) => async dispatch => {
    dispatch(Creators.requestRemoveMyLabel())
    try {
      const {data} = await request({
        method: 'DELETE',
        url: `${API_URL}/label/remove-label`,
        data: {
          id,
        }
      })
      dispatch(Creators.receiveRemoveMyLabel(data))
    } catch (e) {
      dispatch(Creators.receiveRemoveMyLabelError(e))
    }
  },
  getPatientAccount: (patientId) => async dispatch => {
    dispatch(Creators.requestPatientAccount())
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/patient/get-account/?patientId=${patientId}`
      })
      dispatch(Creators.receivePatientAccount(data))
    } catch (e) {
      dispatch(Creators.receivePatientAccountError(e))
    }
  },
  generatePatientAccount: (patientId) => async dispatch => {
    dispatch(Creators.requestPatientAccount())
    try {
      const { data } = await request({
        method: 'POST',
        url: `${API_URL}/patient/create-account/?patientId=${patientId}`
      })
      dispatch(Creators.receivePatientAccount(data))
    } catch (e) {
      dispatch(Creators.receivePatientAccountError(e))
    }
  },
  disablePatientAccount: (patientId) => async dispatch => {
    dispatch(Creators.requestDisablePatientAccount())
    try {
      const { data } = await request({
        method: 'DELETE',
        url: `${API_URL}/patient/disable-account/?patientId=${patientId}`
      })
      dispatch(Creators.receiveDisablePatientAccount(data))
    } catch (e) {
      dispatch(Creators.receiveDisablePatientAccountError(e))
    }
  }
}
