import { request } from '../../config/Http';
import { API_URL } from '../api';
import {Creators} from './actions';

export const Async = {
  getSessions: () => async dispatch => {
    dispatch(Creators.requestSessions())
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/session/get-executed-sessions`
      })

      dispatch(Creators.receiveSessions(data))
    } catch (e) {
      dispatch(Creators.receiveSessionsError(e))
    }

  }
}
