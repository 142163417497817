import { createReducer } from 'reduxsauce';
import { Types } from './actions'

export const INITIAL_STATE = {
    typeProcessingData: null,
    typeProcessingDataLoading: false,
    typeProcessingDataError: null,

    createTypeProcessingSuccess: null,
    createTypeProcessingError: null,
    createTypeProcessingLoading: false,

    editTypeProcessingSuccess: null,
    editTypeProcessingError: null,
    editTypeProcessingLoading: false,

    removeTypeProcessingSuccess: null,
    removeTypeProcessingError: null,
    removeTypeProcessingLoading: false,
}

const resetTypeProcessingState = () => ({
    typeProcessingData: null,
    typeProcessingDataLoading: false,
    typeProcessingDataError: false,

    createTypeProcessingSuccess: null,
    createTypeProcessingError: null,
    createTypeProcessingLoading: false,

    editTypeProcessingSuccess: null,
    editTypeProcessingError: null,
    editTypeProcessingLoading: false,

    removeTypeProcessingSuccess: null,
    removeTypeProcessingError: null,
    removeTypeProcessingLoading: false,
})

const requestTypeProcessing = (state = INITIAL_STATE) => ({
    ...state,
    typeProcessingData: null,
    typeProcessingDataLoading: true,
    typeProcessingDataError: null,
})

const receiveTypeProcessing = (state = INITIAL_STATE, action) => ({
    ...state,
    typeProcessingData: action.data,
    typeProcessingDataLoading: false,
    typeProcessingDataError: null,
})

const receiveTypeProcessingError = (state = INITIAL_STATE, action) => ({
    ...state,
    typeProcessingData: null,
    typeProcessingDataLoading: false,
    typeProcessingDataError: action.error,
})

const requestCreateTypeProcessing = (state = INITIAL_STATE) => ({
    ...state,
    createTypeProcessingSuccess: null,
    createTypeProcessingError: null,
    createTypeProcessingLoading: true,
})

const receiveCreateTypeProcessing = (state = INITIAL_STATE, action) => ({
    ...state,
    createTypeProcessingSuccess: action.data,
    createTypeProcessingError: null,
    createTypeProcessingLoading: false,
    typeProcessingData: state.typeProcessingData ?
        state.typeProcessingData.concat([action.data])
        : [action.data],
})

const receiveCreateTypeProcessingError = (state = INITIAL_STATE, action) => ({
    ...state,
    createTypeProcessingSuccess: null,
    createTypeProcessingError: action.error,
    createTypeProcessingLoading: false,
})

const requestEditTypeProcessing = (state = INITIAL_STATE) => ({
    ...state,
    editTypeProcessingSuccess: null,
    editTypeProcessingError: null,
    editTypeProcessingLoading: true,
})

const receiveEditTypeProcessing = (state = INITIAL_STATE, action) => ({
    ...state,
    editTypeProcessingSuccess: action.data,
    editTypeProcessingError: null,
    editTypeProcessingLoading: false,
    typeProcessingData: state.typeProcessingData?.map((tp) => {
        return tp.id === action.data.id ? action.data : tp
    })
})

const receiveEditTypeProcessingError = (state = INITIAL_STATE, action) => ({
    ...state,
    editTypeProcessingSuccess: null,
    editTypeProcessingError: action.error,
    editTypeProcessingLoading: false,
})

const requestRemoveTypeProcessing = (state = INITIAL_STATE) => ({
    ...state,
    removeTypeProcessingSuccess: null,
    removeTypeProcessingError: null,
    removeTypeProcessingLoading: true,
})

const receiveRemoveTypeProcessing = (state = INITIAL_STATE, action) => ({
    ...state,
    removeTypeProcessingSuccess: action.data,
    removeTypeProcessingError: null,
    removeTypeProcessingLoading: false,
    typeProcessingData: state.typeProcessingData.filter((tp) => {
        return tp.id !== action.data.id
    })
})

const receiveRemoveTypeProcessingError = (state = INITIAL_STATE, action) => ({
    ...state,
    removeTypeProcessingSuccess: null,
    removeTypeProcessingError: action.error,
    removeTypeProcessingLoading: false,
})

export default createReducer(INITIAL_STATE, {
    [Types.RESET_TYPE_PROCESSING_STATE]: resetTypeProcessingState,

    [Types.REQUEST_TYPE_PROCESSING]: requestTypeProcessing,
    [Types.RECEIVE_TYPE_PROCESSING]: receiveTypeProcessing,
    [Types.RECEIVE_TYPE_PROCESSING_ERROR]: receiveTypeProcessingError,

    [Types.REQUEST_CREATE_TYPE_PROCESSING]: requestCreateTypeProcessing,
    [Types.RECEIVE_CREATE_TYPE_PROCESSING]: receiveCreateTypeProcessing,
    [Types.RECEIVE_CREATE_TYPE_PROCESSING_ERROR]: receiveCreateTypeProcessingError,

    [Types.REQUEST_EDIT_TYPE_PROCESSING]: requestEditTypeProcessing,
    [Types.RECEIVE_EDIT_TYPE_PROCESSING]: receiveEditTypeProcessing,
    [Types.RECEIVE_EDIT_TYPE_PROCESSING_ERROR]: receiveEditTypeProcessingError,

    [Types.REQUEST_REMOVE_TYPE_PROCESSING]: requestRemoveTypeProcessing,
    [Types.RECEIVE_REMOVE_TYPE_PROCESSING]: receiveRemoveTypeProcessing,
    [Types.RECEIVE_REMOVE_TYPE_PROCESSING_ERROR]: receiveRemoveTypeProcessingError,
})
