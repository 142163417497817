import { createReducer } from 'reduxsauce';
import { Types } from './actions';

const INITIAL_STATE = {
  supportData: null,
  supportLoading: false,
  supportError: null,

  testData: null,
  testLoading: false,
  testError: null,

  verifyReCaptchaData: null,
  verifyReCaptchaError: null,
  verifyReCaptchaLoading: false,
}

const resetState = () => ({
  supportData: null,
  supportLoading: false,
  supportError: null,
})
// Support

const requestSupport = (state = INITIAL_STATE) => ({
  ...state,
  supportData: null,
  supportLoading: true,
  supportError: null,
})
const receiveSupport = (state = INITIAL_STATE, action) => ({
  ...state,
  supportData: action.data,
  supportLoading: false,
  supportError: null,
})

const receiveSupportError = (state = INITIAL_STATE, action) => ({
  ...state,
  supportData: null,
  supportLoading: false,
  supportError: action.error,
})

const requestVerifyToken = (state = INITIAL_STATE) => ({
  ...state,
  testData: null,
  testLoading: true,
  testError: null,
})

const receiveVerifyToken = (state = INITIAL_STATE, action) => ({
  ...state,
  testData: action.data,
  testLoading: false,
  testError: null,
})

const receiveVerifyTokenError = (state = INITIAL_STATE, action) => ({
  ...state,
  testData: null,
  testLoading: false,
  testError: action.error,
})

const requestVerifyReCaptcha = (state = INITIAL_STATE) => ({
  ...state,
  verifyReCaptchaData: null,
  verifyReCaptchaError: null,
  verifyReCaptchaLoading: true,
})

const receiveVerifyReCaptcha = (state = INITIAL_STATE, action) => ({
  ...state,
  verifyReCaptchaData: action.data,
  verifyReCaptchaError: null,
  verifyReCaptchaLoading: false,
})

const receiveVerifyReCaptchaError = (state = INITIAL_STATE, action) => ({
  ...state,
  verifyReCaptchaData: null,
  verifyReCaptchaError: action.error,
  verifyReCaptchaLoading: false,
})
export default createReducer(INITIAL_STATE, {
  [Types.RESET_STATE]: resetState,
  [Types.REQUEST_SUPPORT]: requestSupport,
  [Types.RECEIVE_SUPPORT]: receiveSupport,
  [Types.RECEIVE_SUPPORT_ERROR]: receiveSupportError,

  [Types.REQUEST_VERIFY_TOKEN]: requestVerifyToken,
  [Types.RECEIVE_VERIFY_TOKEN]: receiveVerifyToken,
  [Types.RECEIVE_VERIFY_TOKEN_ERROR]: receiveVerifyTokenError,

  [Types.REQUEST_VERIFY_RECAPTCHA]: requestVerifyReCaptcha,
  [Types.RECEIVE_VERIFY_RECAPTCHA]: receiveVerifyReCaptcha,
  [Types.RECEIVE_VERIFY_RECAPTCHA_ERROR]: receiveVerifyReCaptchaError,
})
