import { createReducer } from 'reduxsauce';
import {Types} from './actions'
export const INITIAL_STATE = {
  token: null,
  user: null,

  loginLoading: false,
  loginError: null,

  registerData: null,
  registerLoading: false,
  registerError: null,

  forgotPasswordData: null,
  forgotPasswordLoading: false,
  forgotPasswordError: null,

  verifyAuthLoading: false,
  verifyAuthError: null,

  verifyResetLoading: false,
  verifyResetError: null,

  verifyEmailData: null,
  verifyEmailLoading: false,
  verifyEmailError: null,

  resetPasswordData: null,
  resetPasswordLoading: false,
  resetPasswordError: null,

  sendNewEmailVerifiedData: null,
  sendNewEmailVerifiedLoading: false,
  sendNewEmailVerifiedError: null,

  statusErrorAuth: null,

  testData: null,
  getTestLoading: false,
  getTestError: null,

  isPreUserData: null,
  isPreUserLoading: false,
  isPreUserError: null,
}


// reducers
//Reset
const resetRegisterState = (state = INITIAL_STATE) => ({
  ...state,
  registerData: null,
  registerLoading: false,
  registerError: null
})

const resetSendNewEmailState = (state = INITIAL_STATE) => ({
  ...state,
  sendNewEmailVerifiedData: null,
  sendNewEmailVerifiedLoading: false,
  sendNewEmailVerifiedError: null,
})

const resetAuthState = (state = INITIAL_STATE) => {
  localStorage.clear()

  return {
    ...state,
    token: null,
    user: null,
    isNotVerifiedToken: null,
    loginLoading: false,
    loginError: null,
    registerLoading: false,
    registerError: null,
    forgotPasswordData: null,
    forgotPasswordLoading: false,
    forgotPasswordError: null,
    resetPasswordData: null,
    resetPasswordLoading: false,
    resetPasswordError: null,
    verifyResetLoading: false,
    verifyResetError: null,
    registerData: null,
    verifyAuthLoading: false,
    verifyAuthError: null,
    sendNewEmailVerifiedData: null,
    sendNewEmailVerifiedLoading: false,
    sendNewEmailVerifiedError: null,
    verifyEmailData: null,
    verifyEmailLoading: false,
    verifyEmailError: null
  }
}
// Login
const requestAuth = (state = INITIAL_STATE) => ({
  ...state,
  verifyAuthLoading: true,
  verifyAuthError: null,
})

const receiveAuth = (state = INITIAL_STATE, action) => ({
  ...state,
  token: action.token,
  user: action.user,
  verifyAuthLoading: false,
  verifyAuthError: null,
})

const receiveAuthError = (state = INITIAL_STATE, action) => ({
  ...state,
  verifyAuthLoading: false,
  verifyAuthError: action.authError
})


const requestLogin = (state = INITIAL_STATE) => ({
  ...state,
  loginLoading: true
})

const receiveLogin = (state = INITIAL_STATE, action) => ({
  ...state,
  loginLoading: false,
  token: action.token,
  user: action.user
})

const receiveLoginError = (state = INITIAL_STATE, action) => ({
  ...state,
  loginLoading: false,
  loginError: action.loginError
})

// Reset Password

const requestAuthForResetPassword = (state = INITIAL_STATE) => ({
  ...state,
  verifyResetLoading: true,
  verifyResetError: null,
})

const receiveAuthForResetPassword = (state = INITIAL_STATE, action) => ({
  ...state,
  token: action.token,
  verifyResetLoading: false,
  verifyResetError: null,
})

const receiveAuthForResetPasswordError = (state = INITIAL_STATE, action) => ({
  ...state,
  token: null,
  verifyResetLoading: false,
  verifyResetError: action.authError
})

//Register
const requestRegister = (state = INITIAL_STATE) => ({
  ...state,
  registerLoading: !state.registerLoading
})

const receiveRegister = (state = INITIAL_STATE, action) => ({
  ...state,
  registerData: action.data,
  registerLoading: false,
  registerError: null
})
const receiveRegisterError = (state = INITIAL_STATE, action) => ({
  ...state,
  registerLoading: false,
  registerError: action.registerError
})


//Forgot Password

const requestForgotPassword = (state = INITIAL_STATE) => ({
  ...state,
  forgotPasswordLoading: true,
  forgotPasswordError: null
})

const receiveForgotPassword = (state = INITIAL_STATE, action) => ({
  ...state,
  forgotPasswordLoading: false,
  forgotPasswordError: null,
  forgotPasswordData: action.data
})

const receiveForgotPasswordError = (state = INITIAL_STATE, action) => ({
  ...state,
  forgotPasswordLoading: false,
  forgotPasswordError: action.forgotPasswordError
})

// Reset Password
const requestResetPassword = (state = INITIAL_STATE) => ({
  ...state,
  resetPasswordLoading: true,
  resetPasswordError: null
})

const receiveResetPassword = (state = INITIAL_STATE, action) => ({
  ...state,
  resetPasswordLoading: false,
  resetPasswordError: null,
  resetPasswordData: action.data
})

const receiveResetPasswordError = (state = INITIAL_STATE, action) => ({
  ...state,
  resetPasswordLoading: false,
  resetPasswordError: action.resetPasswordError
})

// Send new email for validated
const requestSendNewEmailVerified = (state = INITIAL_STATE, action) => ({
  ...state,
  sendNewEmailVerifiedLoading: true,
  sendNewEmailVerifiedError: null
})

const receiveSendNewEmailVerified = (state = INITIAL_STATE, action) => ({
  ...state,
  sendNewEmailVerifiedData: action.data,
  sendNewEmailVerifiedLoading: false,
  sendNewEmailVerifiedError: null
})

const receiveSendNewEmailVerifiedError = (state = INITIAL_STATE, action) => ({
  ...state,
  sendNewEmailVerifiedLoading: false,
  sendNewEmailVerifiedError: action.sendNewEmailVerifiedError
})

// verify email
const requestVerifyEmail = (state = INITIAL_STATE, action) => ({
  ...state,
  verifyEmailLoading: true,
  verifyEmailError: null
})

const receiveVerifyEmail = (state = INITIAL_STATE, action) => ({
  ...state,
  verifyEmailLoading: false,
  verifyEmailData: action.data,
  verifyEmailError: null
})

const receiveVerifyEmailError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    verifyEmailLoading: false,
    verifyEmailData: null,
    verifyEmailError: action.verifyEmailError.data
  }
}

const requestGetTestData = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    testData: null,
    getTestLoading: true,
    getTestError: null
  }
}

const receiveGetTestData = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    testData: action.data,
    getTestLoading: false,
    getTestError: null
  }
}

const receiveGetTestDataError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    testData: null,
    getTestLoading: false,
    getTestError: action.receiveGetTestDataError
  }
}

const requestIsPreUser = (state = INITIAL_STATE) => ({
  ...state,
  isPreUserData: null,
  isPreUserLoading: true,
  isPreUserError: null,
})

const receiveIsPreUser = (state = INITIAL_STATE, action) => ({
  ...state,
  isPreUserData: action.data,
  isPreUserLoading: false,
  isPreUserError: null,
})

const receiveIsPreUserError = (state = INITIAL_STATE, action) => ({
  ...state,
  isPreUserData: null,
  isPreUserLoading: false,
  isPreUserError: action.error,
})
//
export default createReducer(INITIAL_STATE, {
  [Types.RESET_SEND_NEW_EMAIL_STATE]: resetSendNewEmailState,
  [Types.RESET_AUTH_STATE]: resetAuthState,
  [Types.RESET_REGISTER_STATE]: resetRegisterState,

  [Types.REQUEST_AUTH]: requestAuth,
  [Types.RECEIVE_AUTH]: receiveAuth,
  [Types.RECEIVE_AUTH_ERROR]: receiveAuthError,

  [Types.REQUEST_AUTH_FOR_RESET_PASSWORD]: requestAuthForResetPassword,
  [Types.RECEIVE_AUTH_FOR_RESET_PASSWORD]: receiveAuthForResetPassword,
  [Types.RECEIVE_AUTH_FOR_RESET_PASSWORD_ERROR]: receiveAuthForResetPasswordError,

  [Types.REQUEST_LOGIN]: requestLogin,
  [Types.RECEIVE_LOGIN]: receiveLogin,
  [Types.RECEIVE_LOGIN_ERROR]: receiveLoginError,


  [Types.REQUEST_REGISTER]: requestRegister,
  [Types.RECEIVE_REGISTER]: receiveRegister,
  [Types.RECEIVE_REGISTER_ERROR]: receiveRegisterError,

  [Types.REQUEST_FORGOT_PASSWORD]: requestForgotPassword,
  [Types.RECEIVE_FORGOT_PASSWORD]: receiveForgotPassword,
  [Types.RECEIVE_FORGOT_PASSWORD_ERROR]: receiveForgotPasswordError,

  [Types.REQUEST_RESET_PASSWORD]: requestResetPassword,
  [Types.RECEIVE_RESET_PASSWORD]: receiveResetPassword,
  [Types.RECEIVE_RESET_PASSWORD_ERROR]: receiveResetPasswordError,

  [Types.REQUEST_SEND_NEW_EMAIL_VERIFIED]: requestSendNewEmailVerified,
  [Types.RECEIVE_SEND_NEW_EMAIL_VERIFIED]: receiveSendNewEmailVerified,
  [Types.RECEIVE_SEND_NEW_EMAIL_VERIFIED_ERROR]: receiveSendNewEmailVerifiedError,

  [Types.REQUEST_VERIFY_EMAIL]: requestVerifyEmail,
  [Types.RECEIVE_VERIFY_EMAIL]: receiveVerifyEmail,
  [Types.RECEIVE_VERIFY_EMAIL_ERROR]: receiveVerifyEmailError,

  [Types.REQUEST_GET_TEST_DATA]: requestGetTestData,
  [Types.RECEIVE_GET_TEST_DATA]: receiveGetTestData,
  [Types.RECEIVE_GET_TEST_DATA_ERROR]: receiveGetTestDataError,

  [Types.REQUEST_IS_PRE_USER]: requestIsPreUser,
  [Types.RECEIVE_IS_PRE_USER]: receiveIsPreUser,
  [Types.RECEIVE_IS_PRE_USER_ERROR]: receiveIsPreUserError,

})
