import { request } from '../../config/Http';
import { API_URL } from '../api';
import { Creators } from './actions'
export const Async = {
  verifyTestToken: ({ token }) => async dispatch => {
    dispatch(Creators.requestVerifyToken())
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/test/is-token-valid?token=${token}`,
      })

      dispatch(Creators.receiveVerifyToken(data))
    } catch (e) {
      dispatch(Creators.receiveVerifyTokenError(e))
    }
  },
  sendFeedback: ({ test, email, name, comment }) => async dispatch => {
    dispatch(Creators.requestSupport())
    try {
      const { data } = await request({
        method: 'POST',
        url: `${API_URL}/user/feedback`,
        data: {
          test,
          email,
          name,
          comment
        }
      })

      dispatch(Creators.receiveSupport(data))
    } catch (e) {
      dispatch(Creators.receiveSupportError(e))
    }
  },
  verifyReCaptcha: ({token}) => async dispatch => {
    dispatch(Creators.requestVerifyRecaptcha());
    try {
      const {data} = await request({
        method: 'POST',
        url: `${API_URL}/user/verify-recaptcha`,
        data: {
          token
        }
      });

      dispatch(Creators.receiveVerifyRecaptcha(data));
    } catch (e) {
      dispatch(Creators.receiveVerifyRecaptchaError(e));
    }
  }
}
