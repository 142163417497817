import { request } from '../../config/Http';
import { API_URL } from '../api';
import { Creators } from './actions';

export const Async = {
  getListOfUsers: () => async (dispatch) => {
    dispatch(Creators.requestGetListOfUsers());
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/admin/list-users`,
      });
      dispatch(Creators.receiveGetListOfUsers(data));
    } catch (e) {
      dispatch(Creators.receiveGetListOfUsersError(e));
    }
  },
  verifyUser:
    ({ userId, isVerified }) =>
    async (dispatch) => {
      dispatch(Creators.requestVerifyUser());
      try {
        const { data } = await request({
          method: 'POST',
          url: `${API_URL}/admin/verify-user`,
          data: {
            userId,
            isVerified,
          },
        });
        dispatch(Creators.receiveVerifyUser(data));
      } catch (e) {
        dispatch(Creators.receiveVerifyUserError(e));
      }
    },
  changeUserPermission: (id, newPermissions) => async (dispatch) => {
    dispatch(Creators.requestChangeUserPermission());
    try {
      const { data } = await request({
        method: 'POST',
        url: `${API_URL}/admin/manage-permissions`,
        data: {
          id,
          newPermissions,
        },
      });
      dispatch(Creators.receiveChangeUserPermission(data));
    } catch (e) {
      dispatch(Creators.receiveChangeUserPermissionError(e));
    }
  },
  createUser:
    ({
      name,
      phoneNumber,
      cpf,
      areaOfExpertise,
      identificationNumber,
      gender,
      email,
      password,
      permission,
      city,
    }) =>
    async (dispatch) => {
      dispatch(Creators.requestCreateUser());
      try {
        const { data } = await request({
          method: 'POST',
          url: `${API_URL}/admin/create-user`,
          data: {
            name,
            phoneNumber,
            cpf,
            areaOfExpertise,
            identificationNumber,
            gender,
            email,
            password,
            permission,
            city,
          },
        });
        dispatch(Creators.receiveCreateUser(data));
      } catch (e) {
        dispatch(Creators.receiveCreateUserError(e));
      }
    },
  editUser:
    ({
      id,
      name,
      cpf,
      city,
      areaOfExpertise,
      identificationNumber,
      gender,
      phoneNumber,
      email,
    }) =>
    async (dispatch) => {
      dispatch(Creators.requestEditUser());
      try {
        const { data } = await request({
          method: 'PUT',
          url: `${API_URL}/admin/edit-user`,
          data: {
            id,
            name,
            cpf,
            city,
            areaOfExpertise,
            identificationNumber,
            gender,
            email,
            phoneNumber,
          },
        });
        dispatch(Creators.receiveEditUser(data));
      } catch (e) {
        dispatch(Creators.receiveEditUserError(e));
      }
    },
  removeUser:
    ({ id }) =>
    async (dispatch) => {
      dispatch(Creators.requestRemoveUser());
      try {
        const { data } = await request({
          method: 'DELETE',
          url: `${API_URL}/user/remove`,
          data: {
            id,
          },
        });
        dispatch(Creators.receiveRemoveUser(data, id));
      } catch (e) {
        dispatch(Creators.receiveRemoveUserError(e));
      }
    },
  getPermissions: () => async (dispatch) => {
    dispatch(Creators.restartPermissionsFlow());
    dispatch(Creators.requestPermissions());
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/profile/get-permissions`,
      });
      dispatch(Creators.receivePermissions(data));
    } catch (e) {
      dispatch(Creators.receivePermissionsError(e));
    }
  },
  createPermission:
    ({ name, tag }) =>
    async (dispatch) => {
      dispatch(Creators.requestCreatePermission());
      try {
        const { data } = await request({
          method: 'POST',
          url: `${API_URL}/profile/create-permission`,
          data: {
            name,
            tag,
          },
        });
        dispatch(Creators.receiveCreatePermission(data));
      } catch (e) {
        dispatch(Creators.receiveCreatePermissionError(e));
      }
    },
  removePermission:
    ({ id }) =>
    async (dispatch) => {
      dispatch(Creators.requestRemovePermission());
      try {
        const { data } = await request({
          method: 'DELETE',
          url: `${API_URL}/profile/remove`,
          data: {
            id,
          },
        });
        dispatch(Creators.receiveRemovePermission(data, id));
      } catch (e) {
        dispatch(Creators.receiveRemovePermissionError(e));
      }
    },
  editPermission:
    ({ id, tag, name }) =>
    async (dispatch) => {
      dispatch(Creators.requestEditPermission());
      try {
        const { data } = await request({
          method: 'PUT',
          url: `${API_URL}/profile/edit`,
          data: {
            id,
            tag,
            name,
          },
        });
        dispatch(Creators.receiveEditPermission(data));
      } catch (e) {
        dispatch(Creators.receiveEditPermissionError(e));
      }
    },
  inviteVolunteer:
    ({ emails, batteryId, userId }) =>
    async (dispatch) => {
      dispatch(Creators.requestInviteVolunteer());
      try {
        const { data } = await request({
          method: 'POST',
          url: `${API_URL}/user/invite-volunteer`,
          data: {
            emails,
            batteryId,
            userId,
          },
        });
        dispatch(Creators.receiveInviteVolunteer(data));
      } catch (e) {
        dispatch(Creators.receiveInviteVolunteerError(e));
      }
    },
  getListOfTests: (locale) => async (dispatch) => {
    dispatch(Creators.requestTests());
    try {
      let endpoint = `${API_URL}/test/list-tests`;

      if (locale) {
        endpoint += `?lng=${locale}`;
      }

      const { data } = await request({
        method: 'GET',
        url: endpoint,
      });

      data.sort((a, b) => {
        return a.position - b.position;
      });
      dispatch(Creators.receiveTests(data));
    } catch (e) {
      dispatch(Creators.receiveTestsError(e));
    }
  },
  createTest:
    ({
      title,
      description,
      demoActive,
      domain,
      subDomain,
      system,
      file,
      gif,
      typeProcessingId,
      duration,
      language,
      selectedParameters,
      applicationId,
    }) =>
    async (dispatch) => {
      dispatch(Creators.requestCreateTest());
      try {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('demoActive', demoActive);
        formData.append('domain', domain);
        formData.append('subDomain', subDomain);
        formData.append('system', system);
        formData.append('typeProcessingId', typeProcessingId);
        formData.append('duration', duration);
        formData.append('language', language);
        formData.append('applicationId', applicationId);
        formData.append(
          'selectedParameters',
          JSON.stringify(selectedParameters)
        );

        if (file) {
          formData.append('file', file, file.name);
        }
        if (gif) {
          formData.append('gifFile', gif, gif.name);
        }
        const { data } = await request({
          method: 'POST',
          url: `${API_URL}/test/create`,
          // data: formData
          data: formData,
        });
        dispatch(Creators.receiveCreateTest(data));
      } catch (e) {
        dispatch(Creators.receiveCreateTestError(e));
      }
    },
  editTest:
    ({
      id,
      title,
      description,
      demoActive,
      domain,
      subDomain,
      system,
      file,
      gif,
      typeProcessingId,
      duration,
      language,
      selectedParameters,
      applicationId,
    }) =>
    async (dispatch) => {
      dispatch(Creators.requestEditTest());
      try {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('title', title);
        formData.append('description', description);
        formData.append('demoActive', demoActive);
        formData.append('domain', domain);
        formData.append('subDomain', subDomain);
        formData.append('system', system);
        formData.append('typeProcessingId', typeProcessingId);
        formData.append('duration', duration);
        formData.append('language', language);
        formData.append('applicationId', applicationId);
        formData.append(
          'selectedParameters',
          JSON.stringify(selectedParameters)
        );

        if (file) {
          formData.append('file', file, file.name);
        }
        if (gif) {
          formData.append('gifFile', gif, gif.name);
        }
        const { data } = await request({
          method: 'PUT',
          url: `${API_URL}/test/update`,
          data: formData,
        });
        dispatch(Creators.receiveEditTest(data));
      } catch (e) {
        dispatch(Creators.receiveEditTestError(e));
      }
    },
  removeTest:
    ({ id }) =>
    async (dispatch) => {
      dispatch(Creators.requestRemoveTest());
      try {
        const { data } = await request({
          method: 'DELETE',
          url: `${API_URL}/test/remove`,
          data: {
            id,
          },
        });
        dispatch(Creators.receiveRemoveTest(data));
      } catch (e) {
        dispatch(Creators.receiveRemoveTestError(e));
      }
    },
  reorganizeTests:
    ({ allTests }) =>
    async (dispatch) => {
      dispatch(Creators.requestReorganizeTests());
      try {
        const tests = allTests.map((test, index) => {
          return {
            position: index + 1,
            id: test.id,
          };
        });

        const { data } = await request({
          method: 'PUT',
          url: `${API_URL}/test/reorganize-tests`,
          data: {
            tests,
          },
        });
        dispatch(Creators.receiveReorganizeTests(data));
      } catch (e) {
        dispatch(Creators.receiveReorganizeTestsError(e));
      }
    },
  getListOfBatteries: (locale) => async (dispatch) => {
    dispatch(Creators.requestBatteries());
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/battery/list-batteries`,
        params: {
          locale,
        },
      });

      dispatch(Creators.receiveBatteries(data));
    } catch (e) {
      dispatch(Creators.receiveBatteriesError(e));
    }
  },
  createBattery:
    ({ title, description, publicLinkActivated, steps, file, language }) =>
    async (dispatch) => {
      dispatch(Creators.requestCreateBattery());
      try {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('publicLinkActivated', publicLinkActivated);
        formData.append('steps', JSON.stringify(steps));
        formData.append('language', language);

        if (file) {
          formData.append('file', file, file.name);
        }
        const { data } = await request({
          method: 'POST',
          url: `${API_URL}/battery/create`,
          data: formData,
        });
        dispatch(Creators.receiveCreateBattery(data));
      } catch (e) {
        dispatch(Creators.receiveCreateBatteryError(e));
      }
    },
  editBattery:
    ({ id, title, description, publicLinkActivated, steps, file, language }) =>
    async (dispatch) => {
      dispatch(Creators.requestEditBattery());
      try {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('title', title);
        formData.append('description', description);
        formData.append('publicLinkActivated', publicLinkActivated);
        formData.append('steps', JSON.stringify(steps));
        formData.append('language', language);

        if (file) {
          formData.append('file', file, file.name);
        }
        const { data } = await request({
          method: 'PUT',
          url: `${API_URL}/battery/edit`,
          data: formData,
        });
        dispatch(Creators.receiveEditBattery(data));
      } catch (e) {
        dispatch(Creators.receiveEditBatteryError(e));
      }
    },
  addRelationTestBattery:
    ({ batteryId, testId }) =>
    async (dispatch) => {
      dispatch(Creators.requestAddRelationTestBattery());
      try {
        const { data } = await request({
          method: 'PUT',
          url: `${API_URL}/battery/add-relation-test`,
          data: {
            batteryId,
            testId,
          },
        });
        dispatch(Creators.receiveAddRelationTestBattery(data));
      } catch (e) {
        dispatch(Creators.receiveAddRelationTestBatteryError(e));
      }
    },
  removeRelationTestBattery:
    ({ batteryId, batteryTestId }) =>
    async (dispatch) => {
      dispatch(Creators.requestRemoveRelationTestBattery());
      try {
        const { data } = await request({
          method: 'DELETE',
          url: `${API_URL}/battery/remove-relation-test`,
          data: {
            batteryId,
            batteryTestId,
          },
        });
        dispatch(Creators.receiveRemoveRelationTestBattery(data));
      } catch (e) {
        dispatch(Creators.receiveRemoveRelationTestBatteryError(e));
      }
    },
  removeBattery:
    ({ id }) =>
    async (dispatch) => {
      dispatch(Creators.requestRemoveBattery());
      try {
        const { data } = await request({
          method: 'DELETE',
          url: `${API_URL}/battery/remove-battery`,
          data: {
            id,
          },
        });
        dispatch(Creators.receiveRemoveBattery(data));
      } catch (e) {
        dispatch(Creators.receiveRemoveBatteryError(e));
      }
    },
  getListVolunteers: () => async (dispatch) => {
    dispatch(Creators.requestListVolunteers());
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/volunteer/list`,
      });

      dispatch(Creators.receiveListVolunteers(data));
    } catch (e) {
      dispatch(Creators.receiveListVolunteersError(e));
    }
  },
  resendEmailVolunteer:
    ({ volunteerId }) =>
    async (dispatch) => {
      dispatch(Creators.requestResendVolunteer());
      try {
        const { data } = await request({
          method: 'POST',
          url: `${API_URL}/volunteer/resend-email`,
          data: {
            volunteerId,
          },
        });

        dispatch(Creators.receiveResendVolunteer(data));
      } catch (e) {
        dispatch(Creators.receiveResendVolunteerError(e));
      }
    },
  copyLinkVolunteer:
    ({ sessionId, token }) =>
    async (dispatch) => {
      dispatch(Creators.requestCopyLinkVolunteer());
      try {
        const { data } = await request({
          method: 'POST',
          url: `${API_URL}/volunteer/copy-link`,
          data: {
            sessionId,
            token,
          },
        });
        dispatch(Creators.receiveCopyLinkVolunteer(data));
      } catch (e) {
        dispatch(Creators.receiveCopyLinkVolunteerError(e));
      }
    },
  createTeam:
    ({ name, description }) =>
    async (dispatch) => {
      dispatch(Creators.requestCreateTeam());
      try {
        const { data } = await request({
          method: 'POST',
          url: `${API_URL}/team/create`,
          data: {
            name,
            description,
          },
        });

        dispatch(Creators.receiveCreateTeam(data));
      } catch (e) {
        dispatch(Creators.receiveCreateTeamError(e));
      }
    },
  editTeam:
    ({ teamId, name, description }) =>
    async (dispatch) => {
      dispatch(Creators.requestEditTeam());
      try {
        const { data } = await request({
          method: 'PUT',
          url: `${API_URL}/team/edit`,
          data: {
            teamId,
            name,
            description,
          },
        });

        dispatch(Creators.receiveEditTeam(data));
      } catch (e) {
        dispatch(Creators.receiveEditTeamError(e));
      }
    },
  removeTeam:
    ({ id }) =>
    async (dispatch) => {
      dispatch(Creators.requestRemoveTeam());
      try {
        const { data } = await request({
          method: 'DELETE',
          url: `${API_URL}/team/remove`,
          data: {
            teamId: id,
          },
        });

        dispatch(Creators.receiveRemoveTeam(data));
      } catch (e) {
        dispatch(Creators.receiveRemoveTeamError(e));
      }
    },
  getTeams: () => async (dispatch) => {
    dispatch(Creators.requestTeams());
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/team/list-teams`,
      });

      dispatch(Creators.receiveTeams(data));
    } catch (e) {
      dispatch(Creators.receiveTeamsError(e));
    }
  },
  addMemberTeam:
    ({ userId, teamId }) =>
    async (dispatch) => {
      dispatch(Creators.requestAddMemberTeam());
      try {
        const { data } = await request({
          method: 'POST',
          url: `${API_URL}/team/add-member`,
          data: {
            userId,
            teamId,
          },
        });
        dispatch(Creators.receiveAddMemberTeam(data));
      } catch (e) {
        dispatch(Creators.receiveAddMemberTeamError(e));
      }
    },
  removeMemberTeam:
    ({ userId, teamId }) =>
    async (dispatch) => {
      dispatch(Creators.requestRemoveMemberTeam());
      try {
        const { data } = await request({
          method: 'DELETE',
          url: `${API_URL}/team/remove-member`,
          data: {
            userId,
            teamId,
          },
        });
        dispatch(Creators.receiveRemoveMemberTeam(data));
      } catch (e) {
        dispatch(Creators.receiveRemoveMemberTeamError(e));
      }
    },
  getListLabels: () => async (dispatch) => {
    dispatch(Creators.requestLabels());
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/label/list-labels-admin`,
      });
      dispatch(Creators.receiveLabels(data));
    } catch (e) {
      dispatch(Creators.receiveLabelsError(e));
    }
  },
  createLabel:
    ({ name, description }) =>
    async (dispatch) => {
      dispatch(Creators.requestCreateLabel());
      try {
        const { data } = await request({
          method: 'POST',
          url: `${API_URL}/label/create-label-admin`,
          data: {
            name,
            description,
          },
        });
        dispatch(Creators.receiveCreateLabel(data));
      } catch (e) {
        dispatch(Creators.receiveCreateLabelError(e));
      }
    },
  editLabel:
    ({ id, name, description }) =>
    async (dispatch) => {
      dispatch(Creators.requestEditLabel());
      try {
        const { data } = await request({
          method: 'PUT',
          url: `${API_URL}/label/edit-label-admin`,
          data: {
            id,
            name,
            description,
          },
        });
        dispatch(Creators.receiveEditLabel(data));
      } catch (e) {
        dispatch(Creators.receiveEditLabelError(e));
      }
    },
  removeLabel:
    ({ id }) =>
    async (dispatch) => {
      dispatch(Creators.requestRemoveLabel());
      try {
        const { data } = await request({
          method: 'DELETE',
          url: `${API_URL}/label/remove-label-admin`,
          data: {
            id,
          },
        });
        dispatch(Creators.receiveRemoveLabel(data));
      } catch (e) {
        dispatch(Creators.receiveRemoveLabelError(e));
      }
    },
  getListFeedbacks: () => async (dispatch) => {
    dispatch(Creators.requestFeedbacks());
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/admin/list-feedbacks`,
      });
      dispatch(Creators.receiveFeedbacks(data));
    } catch (e) {
      dispatch(Creators.receiveFeedbacksError(e));
    }
  },
  createBatteryTestConnection: (ownerTest, targetTests) => async (dispatch) => {
    dispatch(Creators.requestCreateBatteryTestConnection());
    try {
      await request({
        method: 'POST',
        url: `${API_URL}/battery/connect-tests`,
        data: {
          ownerTest,
          targetTests,
        },
      });
      dispatch(Creators.receiveCreateBatteryTestConnection());
    } catch (e) {
      dispatch(Creators.receiveCreateBatteryTestConnectionError(e));
    }
  },
  configureAppParameters: (applicationId, params) => async (dispatch) => {
    dispatch(Creators.requestConfigParams());
    try {
      await request({
        method: 'PUT',
        url: `${API_URL}/application/parameters`,
        data: {
          applicationId,
          params,
        },
      });
      dispatch(Creators.receiveConfigParamsSuccess());
    } catch (e) {
      dispatch(Creators.receiveConfigParamsError(e));
    }
  },
  configureTestDemos: (testId, demos) => async (dispatch) => {
    dispatch(Creators.requestConfigDemos());
    try {
      await request({
        method: 'POST',
        url: `${API_URL}/test/demo`,
        data: {
          testId,
          demos,
        },
      });
      dispatch(Creators.receiveConfigDemosSuccess());
    } catch (e) {
      dispatch(Creators.receiveConfigDemos(e));
    }
  },
  getListOfApplications: () => async (dispatch) => {
    dispatch(Creators.requestApplications());
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/application`,
      });
      dispatch(Creators.receiveApplications(data));
    } catch (e) {
      dispatch(Creators.receiveApplicationsError(e));
    }
  },
  createApplication:
    ({ name, gitUrl, url }) =>
    async (dispatch) => {
      dispatch(Creators.requestCreateApplication());
      try {
        await request({
          method: 'POST',
          url: `${API_URL}/application/create`,
          data: {
            name,
            gitUrl,
            url,
          },
        });
        dispatch(Creators.receiveCreateApplication());
      } catch (e) {
        dispatch(Creators.receiveCreateApplicationError(e));
      }
    },
  editApplication:
    ({ id, name, gitUrl, url }) =>
    async (dispatch) => {
      dispatch(Creators.requestEditApplication());
      try {
        await request({
          method: 'PUT',
          url: `${API_URL}/application/edit`,
          data: {
            id,
            name,
            gitUrl,
            url,
          },
        });
        dispatch(Creators.receiveEditApplication());
      } catch (e) {
        dispatch(Creators.receiveEditApplicationError(e));
      }
    },
  deleteApplication: (id) => async (dispatch) => {
    dispatch(Creators.requestDeleteApplication());
    try {
      await request({
        method: 'DELETE',
        url: `${API_URL}/application/delete`,
        params: {
          id,
        },
      });
      dispatch(Creators.receiveDeleteApplication());
    } catch (e) {
      dispatch(Creators.receiveDeleteApplicationError(e));
    }
  },
};
