import { createReducer } from 'reduxsauce';
import { Types } from './actions';

export const INITIAL_STATE = {
  usersData: null,
  usersLoading: false,
  usersError: null,

  changePermissionLoading: false,
  changePermissionError: null,

  createUserSuccess: null,
  createUserLoading: false,
  createUserError: null,

  removeUserSuccess: null,
  removeUserLoading: false,
  removeUserError: null,

  editUserSuccess: null,
  editUserLoading: false,
  editUserError: null,

  permissionsData: null,
  permissionsLoading: false,
  permissionsError: null,

  createPermissionSuccess: null,
  createPermissionLoading: false,
  createPermissionError: null,

  removePermissionSuccess: null,
  removePermissionLoading: false,
  removePermissionError: null,

  editPermissionSuccess: null,
  editPermissionLoading: false,
  editPermissionError: null,

  inviteVolunteerSuccess: null,
  inviteVolunteerLoading: false,
  inviteVolunteerError: null,

  createTestSuccess: null,
  createTestLoading: false,
  createTestError: null,

  testsData: null,
  testsLoading: false,
  testsError: null,

  editTestSuccess: null,
  editTestLoading: false,
  editTestError: null,

  removeTestSuccess: null,
  removeTestLoading: false,
  removeTestError: null,

  reorganizeTestsSuccess: null,
  reorganizeTestsLoading: false,
  reorganizeTestsError: null,

  createBatterySuccess: null,
  createBatteryLoading: false,
  createBatteryError: null,

  batteriesData: null,
  batteriesLoading: false,
  batteriesError: null,

  editBatterySuccess: null,
  editBatteryLoading: false,
  editBatteryError: null,

  removeBatterySuccess: null,
  removeBatteryLoading: false,
  removeBatteryError: null,

  addRelationTestBatterySuccess: null,
  addRelationTestBatteryLoading: false,
  addRelationTestBatteryError: null,

  removeRelationTestBatterySuccess: null,
  removeRelationTestBatteryLoading: false,
  removeRelationTestBatteryError: null,

  verifyUserSuccess: null,
  verifyUserLoading: false,
  verifyUserError: null,

  statusErrorAdmin: null,

  listVolunteersData: null,
  listVolunteersLoading: false,
  listVolunteersError: null,

  resendVolunteerData: null,
  resendVolunteerError: null,
  resendVolunteerLoading: false,

  createTeamSuccess: null,
  createTeamLoading: false,
  createTeamError: null,

  editTeamSuccess: null,
  editTeamLoading: false,
  editTeamError: null,

  removeTeamSuccess: null,
  removeTeamLoading: false,
  removeTeamError: null,

  teamsData: null,
  teamsLoading: false,
  teamsError: null,

  addMemberTeamSuccess: null,
  addMemberTeamLoading: null,
  addMemberTeamError: null,

  removeMemberTeamSuccess: null,
  removeMemberTeamLoading: null,
  removeMemberTeamError: null,

  copyLinkVolunteerData: null,
  copyLinkVolunteerError: null,
  copyLinkVolunteerLoading: false,

  labelsData: null,
  labelsLoading: false,
  labelsError: null,

  createLabelSuccess: null,
  createLabelLoading: false,
  createLabelError: null,

  editLabelSuccess: null,
  editLabelLoading: false,
  editLabelError: null,

  removeLabelSuccess: null,
  removeLabelLoading: false,
  removeLabelError: null,

  feedbacksData: null,
  feedbacksLoading: false,
  feedbacksError: null,

  createBatteryTestConnectionLoading: false,
  createBatteryTestConnectionSuccess: false,
  createBatteryTestConnectionError: null,

  configParamsSuccess: false,
  configParamsLoading: false,
  configParamsError: null,

  configDemosSuccess: false,
  configDemosLoading: false,
  configDemosError: null,

  appsData: null,
  appsLoading: false,
  appsError: null,

  createAppSuccess: false,
  createAppLoading: false,
  createAppError: null,

  editAppSuccess: false,
  editAppLoading: false,
  editAppError: null,

  deleteAppSuccess: false,
  deleteAppLoading: false,
  deleteAppError: null,
};

// reducers

// reset

const restartStatusErrorAdmin = (state = INITIAL_STATE) => ({
  ...state,
  statusErrorAdmin: null,
});

const restartPermissionsFlow = (state = INITIAL_STATE) => ({
  ...state,
  createPermissionSuccess: null,
  createPermissionLoading: false,
  createPermissionError: null,
});
const restartVerifyUser = (state = INITIAL_STATE) => ({
  ...state,
  verifyUserSuccess: null,
  verifyUserLoading: false,
  verifyUserError: null,
});
const restartCreateUserFlow = (state = INITIAL_STATE) => ({
  ...state,
  createUserSuccess: null,
  createUserLoading: false,
  createUserError: null,
});

const restartRemoveUserFlow = (state = INITIAL_STATE) => ({
  ...state,
  removeUserSuccess: null,
  removeUserLoading: false,
  removeUserError: null,
});

const restartEditUserFlow = (state = INITIAL_STATE) => ({
  ...state,
  editUserSuccess: null,
  editUserLoading: false,
  editUserError: null,
});

const restartRemovePermissionFlow = (state = INITIAL_STATE) => ({
  ...state,
  removePermissionSuccess: null,
  removePermissionLoading: false,
  removePermissionError: null,
});

const restartEditPermissionFlow = (state = INITIAL_STATE) => ({
  ...state,
  editPermissionSuccess: null,
  editPermissionLoading: false,
  editPermissionError: null,
});

const restartInviteVolunteerFlow = (state = INITIAL_STATE) => ({
  ...state,
  inviteVolunteerSuccess: null,
  inviteVolunteerLoading: false,
  inviteVolunteerError: null,
});

const restartCreateTestFlow = (state = INITIAL_STATE) => ({
  ...state,
  createTestLoading: false,
  createTestSuccess: null,
  createTestError: null,
});

const restartEditTestFlow = (state = INITIAL_STATE) => ({
  ...state,
  editTestLoading: false,
  editTestSuccess: null,
  editTestError: null,
});

const restartRemoveTestFlow = (state = INITIAL_STATE) => ({
  ...state,
  removeTestLoading: false,
  removeTestSuccess: null,
  removeTestError: null,
});

const restartCreateBatteryFlow = (state = INITIAL_STATE) => ({
  ...state,
  createBatteryLoading: false,
  createBatterySuccess: null,
  createBatteryError: null,
});

const restartEditBatteryFlow = (state = INITIAL_STATE) => ({
  ...state,
  editBatteryLoading: false,
  editBatterySuccess: null,
  editBatteryError: null,
});

const restartAddRelationTestBatteryFlow = (state = INITIAL_STATE) => ({
  ...state,
  addRelationTestBatterySuccess: null,
  addRelationTestBatteryLoading: false,
  addRelationTestBatteryError: null,
});

const restartRemoveRelationTestBatteryFlow = (state = INITIAL_STATE) => ({
  ...state,
  removeRelationTestBatterySuccess: null,
  removeRelationTestBatteryLoading: false,
  removeRelationTestBatteryError: null,
});

const restartRemoveBatteryFlow = (state = INITIAL_STATE) => ({
  ...state,
  removeBatteryLoading: false,
  removeBatterySuccess: null,
  removeBatteryError: null,
});

const restartCopyLinkVolunteer = (state = INITIAL_STATE) => ({
  ...state,
  copyLinkVolunteerData: null,
  copyLinkVolunteerError: null,
  copyLinkVolunteerLoading: false,
});
const restartEditTeamFlow = (state = INITIAL_STATE) => ({
  ...state,
  editTeamLoading: false,
  editTeamSuccess: null,
  editTeamError: null,
});

const restartCreateTeamFlow = (state = INITIAL_STATE) => ({
  ...state,
  createTeamLoading: false,
  createTeamSuccess: null,
  createTeamError: null,
});

const restartRemoveTeamFlow = (state = INITIAL_STATE) => ({
  ...state,
  removeTeamLoading: false,
  removeTeamSuccess: null,
  removeTeamError: null,
});

const restartAddMemberTeamFlow = (state = INITIAL_STATE) => ({
  ...state,
  addMemberTeamSuccess: null,
  addMemberTeamLoading: false,
  addMemberTeamError: null,
});

const restartRemoveMemberTeamFlow = (state = INITIAL_STATE) => ({
  ...state,
  removeMemberTeamSuccess: null,
  removeMemberTeamLoading: false,
  removeMemberTeamError: null,
});

const restartResendEmailFlow = (state = INITIAL_STATE) => ({
  ...state,
  resendVolunteerData: null,
  resendVolunteerError: null,
  resendVolunteerLoading: false,
});

const restartCreateLabelFlow = (state = INITIAL_STATE) => ({
  ...state,
  createLabelSuccess: null,
  createLabelLoading: false,
  createLabelError: null,
});

const restartEditLabelFlow = (state = INITIAL_STATE) => ({
  ...state,
  editLabelSuccess: null,
  editLabelLoading: false,
  editLabelError: null,
});

const restartRemoveLabelFlow = (state = INITIAL_STATE) => ({
  ...state,
  removeLabelSuccess: null,
  removeLabelLoading: false,
  removeLabelError: null,
});

// Get List Of Users
const requestGetListOfUsers = (state = INITIAL_STATE) => ({
  ...state,
  usersData: null,
  usersLoading: true,
  usersError: null,
});
const receiveGetListOfUsers = (state = INITIAL_STATE, action) => ({
  ...state,
  usersData: action.data,
  usersLoading: false,
  usersError: null,
});
const receiveGetListOfUsersError = (state = INITIAL_STATE, action) => ({
  ...state,
  usersData: null,
  usersLoading: false,
  usersError: action.error,
  statusErrorAdmin: action.error.status,
});

// verify user
const requestVerifyUser = (state = INITIAL_STATE, action) => ({
  ...state,
  verifyUserSuccess: null,
  verifyUserLoading: true,
  verifyUserError: null,
});
const receiveVerifyUser = (state = INITIAL_STATE, action) => ({
  ...state,
  usersData: state.usersData.map((user) => {
    return user.id === action.data.userId
      ? {
          ...user,
          isVerified: action.data.isVerified,
        }
      : user;
  }),
  verifyUserSuccess: action.data,
  verifyUserLoading: false,
  verifyUserError: null,
});
const receiveVerifyUserError = (state = INITIAL_STATE, action) => ({
  ...state,
  verifyUserSuccess: null,
  verifyUserLoading: false,
  verifyUserError: action.error,
  statusErrorAdmin: action.error.status,
});

// Change User Permission
const requestChangeUserPermission = (state = INITIAL_STATE) => ({
  ...state,
  changePermissionLoading: true,
  changePermissionError: null,
});
const receiveChangeUserPermission = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    usersData: state.usersData.map((user) => {
      return user.id === action.data.id
        ? {
            ...user,
            permission: action.data.permission,
          }
        : user;
    }),
    changePermissionLoading: false,
    changePermissionError: null,
  };
};

const receiveChangeUserPermissionError = (state = INITIAL_STATE, action) => ({
  ...state,
  changePermissionLoading: false,
  changePermissionPermission: action.error,
  statusErrorAdmin: action.error.status,
});

// Create Users
const requestCreateUser = (state = INITIAL_STATE) => ({
  ...state,
  createUserSuccess: null,
  createUserLoading: true,
  createUserError: null,
});

const receiveCreateUser = (state = INITIAL_STATE, action) => ({
  ...state,
  usersData: state.usersData
    ? state.usersData.concat(action.data)
    : [action.data],
  createUserSuccess: action.data,
  createUserLoading: false,
  createUserError: null,
});

const receiveCreateUserError = (state = INITIAL_STATE, action) => ({
  ...state,
  createUserSuccess: null,
  createUserLoading: false,
  createUserError: action.error,
  statusErrorAdmin: action.error.status,
});

// Remove User
const requestRemoveUser = (state = INITIAL_STATE) => ({
  ...state,
  removeUserSuccess: null,
  removeUserLoading: true,
  removeUserError: null,
});

const receiveRemoveUser = (state = INITIAL_STATE, action) => ({
  ...state,
  usersData: state.usersData.filter((user) => user.id !== action.userId),
  removeUserSuccess: action.data,
  removeUserLoading: false,
  removeUserError: null,
});

const receiveRemoveUserError = (state = INITIAL_STATE, action) => ({
  ...state,
  removeUserSuccess: null,
  removeUserLoading: false,
  removeUserError: action.error,
  statusErrorAdmin: action.error.status,
});

// Edit User
const requestEditUser = (state = INITIAL_STATE) => ({
  ...state,
  editUserSuccess: null,
  editUserLoading: true,
  editUserError: null,
});

const receiveEditUser = (state = INITIAL_STATE, action) => ({
  ...state,
  usersData: state.usersData.map((user) => {
    if (user.id === action.data.id) {
      return action.data;
    }

    return user;
  }),
  editUserSuccess: action.data,
  editUserLoading: false,
  editUserError: null,
});

const receiveEditUserError = (state = INITIAL_STATE, action) => ({
  ...state,
  editUserSuccess: null,
  editUserLoading: false,
  editUserError: action.error,
  statusErrorAdmin: action.error.status,
});
// Get Permissions

const requestPermissions = (state = INITIAL_STATE) => ({
  ...state,
  permissionsLoading: true,
  permissionsError: null,
});

const receivePermissions = (state = INITIAL_STATE, action) => ({
  ...state,
  permissionsData: action.data,
  permissionsLoading: false,
  permissionsError: null,
});

const receivePermissionsError = (state = INITIAL_STATE, action) => ({
  ...state,
  permissionsLoading: false,
  permissionsError: action.error,
  statusErrorAdmin: action.error.status,
});

// Create Permissions
const requestCreatePermission = (state = INITIAL_STATE) => ({
  ...state,
  createPermissionSuccess: null,
  createPermissionLoading: true,
  createPermissionError: null,
});

const receiveCreatePermission = (state = INITIAL_STATE, action) => ({
  ...state,
  permissionsData: state.permissionsData
    ? state.permissionsData.concat([action.data])
    : [action.data],
  createPermissionSuccess: true,
  createPermissionLoading: false,
  createPermissionError: null,
});

const receiveCreatePermissionError = (state = INITIAL_STATE, action) => ({
  ...state,
  createPermissionSuccess: null,
  createPermissionLoading: false,
  createPermissionError: action.error,
  statusErrorAdmin: action.error.status,
});

// Remove Permission
const requestRemovePermission = (state = INITIAL_STATE) => ({
  ...state,
  removePermissionSuccess: null,
  removePermissionLoading: true,
  removePermissionError: null,
});

const receiveRemovePermission = (state = INITIAL_STATE, action) => ({
  ...state,
  permissionsData: state.permissionsData.filter(
    (permission) => permission.id !== action.permissionId
  ),
  removePermissionSuccess: true,
  removePermissionLoading: false,
  removePermissionError: null,
});

const receiveRemovePermissionError = (state = INITIAL_STATE, action) => ({
  ...state,
  removePermissionSuccess: null,
  removePermissionLoading: false,
  removePermissionError: action.error,
  statusErrorAdmin: action.error.status,
});

// Edit Permission
const requestEditPermission = (state = INITIAL_STATE) => ({
  ...state,
  editPermissionSuccess: null,
  editPermissionLoading: true,
  editPermissionError: null,
});

const receiveEditPermission = (state = INITIAL_STATE, action) => ({
  ...state,
  permissionsData: state.permissionsData.map((permission) =>
    permission.id === action.data.id ? action.data : permission
  ),
  editPermissionSuccess: true,
  editPermissionLoading: false,
  editPermissionError: null,
});

const receiveEditPermissionError = (state = INITIAL_STATE, action) => ({
  ...state,
  editPermissionSuccess: null,
  editPermissionLoading: false,
  editPermissionError: action.error,
  statusErrorAdmin: action.error.status,
});

const requestInviteVolunteer = (state = INITIAL_STATE) => ({
  ...state,
  inviteVolunteerSuccess: null,
  inviteVolunteerLoading: true,
  inviteVolunteerError: null,
});

const receiveInviteVolunteer = (state = INITIAL_STATE, action) => ({
  ...state,
  inviteVolunteerSuccess: action.data,
  inviteVolunteerLoading: false,
  inviteVolunteerError: null,
});

const receiveInviteVolunteerError = (state = INITIAL_STATE, action) => ({
  ...state,
  inviteVolunteerSuccess: null,
  inviteVolunteerLoading: false,
  inviteVolunteerError: action.error,
  statusErrorAdmin: action.error.status,
});

// Get AdminManageTests
const requestTests = (state = INITIAL_STATE) => ({
  ...state,
  testsLoading: true,
  testsData: null,
  testsError: null,
});

const receiveTests = (state = INITIAL_STATE, action) => ({
  ...state,
  testsLoading: false,
  testsData: action.data,
  testsError: null,
});

const receiveTestsError = (state = INITIAL_STATE, action) => ({
  ...state,
  testsLoading: false,
  testsData: null,
  testsError: action.error,
  statusErrorAdmin: action.error.status,
});
// Create Battery
const requestCreateTest = (state = INITIAL_STATE) => ({
  ...state,
  createTestSuccess: null,
  createTestLoading: true,
  createTestError: null,
});

const receiveCreateTest = (state = INITIAL_STATE, action) => ({
  ...state,
  createTestSuccess: action.data,
  testsData: state.testsData
    ? state.testsData.concat([action.data])
    : [action.data],
  createTestLoading: false,
  createTestError: null,
});

const receiveCreateTestError = (state = INITIAL_STATE, action) => ({
  ...state,
  createTestSuccess: null,
  createTestLoading: false,
  createTestError: action.error,
  statusErrorAdmin: action.error.status,
});

// Edit Battery

const requestEditTest = (state = INITIAL_STATE) => ({
  ...state,
  editTestSuccess: null,
  editTestLoading: true,
  editTestError: null,
});

const receiveEditTest = (state = INITIAL_STATE, action) => ({
  ...state,
  testsData: state.testsData.map((test) =>
    test.id === action.data.id ? action.data : test
  ),
  editTestSuccess: action.data,
  editTestLoading: false,
  editTestError: null,
});

const receiveEditTestError = (state = INITIAL_STATE, action) => ({
  ...state,
  editTestSuccess: null,
  editTestLoading: false,
  editTestError: action.error,
  statusErrorAdmin: action.error.status,
});

// Remove Battery

const requestRemoveTest = (state = INITIAL_STATE) => ({
  ...state,
  removeTestSuccess: null,
  removeTestLoading: true,
  removeTestError: null,
});

const receiveRemoveTest = (state = INITIAL_STATE, action) => ({
  ...state,
  testsData: state.testsData.filter((test) => test.id !== action.data.id),
  removeTestSuccess: action.data,
  removeTestLoading: false,
  removeTestError: null,
});

const receiveRemoveTestError = (state = INITIAL_STATE, action) => ({
  ...state,
  removeTestSuccess: null,
  removeTestLoading: false,
  removeTestError: action.error,
  statusErrorAdmin: action.error.status,
});

// Reorganize AdminManageTests

const requestReorganizeTests = (state = INITIAL_STATE) => ({
  ...state,
  reorganizeTestsSuccess: null,
  reorganizeTestsLoading: true,
  reorganizeTestsError: null,
});

const receiveReorganizeTests = (state = INITIAL_STATE, action) => ({
  ...state,
  reorganizeTestsSuccess: action.data,
  reorganizeTestsLoading: false,
  reorganizeTestsError: null,
});

const receiveReorganizeTestsError = (state = INITIAL_STATE, action) => ({
  ...state,
  reorganizeTestsSuccess: null,
  reorganizeTestsLoading: false,
  reorganizeTestsError: action.error,
  statusErrorAdmin: action.error.status,
});

// Get Batteries
const requestBatteries = (state = INITIAL_STATE) => ({
  ...state,
  batteriesLoading: true,
  batteriesData: null,
  batteriesError: null,
});

const receiveBatteries = (state = INITIAL_STATE, action) => ({
  ...state,
  batteriesLoading: false,
  batteriesData: action.data,
  batteriesError: null,
});

const receiveBatteriesError = (state = INITIAL_STATE, action) => ({
  ...state,
  batteriesLoading: false,
  batteriesData: null,
  batteriesError: action.error,
  statusErrorAdmin: action.error.status,
});

// Create Battery
const requestCreateBattery = (state = INITIAL_STATE) => ({
  ...state,
  createBatterySuccess: null,
  createBatteryLoading: true,
  createBatteryError: null,
});

const receiveCreateBattery = (state = INITIAL_STATE, action) => ({
  ...state,
  createBatterySuccess: action.data,
  batteriesData: state.batteriesData
    ? state.batteriesData.concat([action.data])
    : [action.data],
  createBatteryLoading: false,
  createBatteryError: null,
});

const receiveCreateBatteryError = (state = INITIAL_STATE, action) => ({
  ...state,
  createBatterySuccess: null,
  createBatteryLoading: false,
  createBatteryError: action.error,
  statusErrorAdmin: action.error.status,
});

// Edit Battery

const requestEditBattery = (state = INITIAL_STATE) => ({
  ...state,
  editBatterySuccess: null,
  editBatteryLoading: true,
  editBatteryError: null,
});

const receiveEditBattery = (state = INITIAL_STATE, action) => ({
  ...state,
  // batteriesData: state.batteriesData.map((battery) =>
  //   battery.id === action.data.id ? action.data : battery
  // ),
  editBatterySuccess: action.data,
  editBatteryLoading: false,
  editBatteryError: null,
});

const receiveEditBatteryError = (state = INITIAL_STATE, action) => ({
  ...state,
  editBatterySuccess: null,
  editBatteryLoading: false,
  editBatteryError: action.error,
  statusErrorAdmin: action.error.status,
});

// Add relation test with battery

const requestAddRelationTestBattery = (state = INITIAL_STATE) => ({
  ...state,
  addRelationTestBatterySuccess: null,
  addRelationTestBatteryLoading: true,
  addRelationTestBatteryError: null,
});

const receiveAddRelationTestBattery = (state = INITIAL_STATE, action) => ({
  ...state,
  batteriesData: state.batteriesData.map((battery) => {
    if (battery.id === action.data.batteryId) {
      battery.tests = action.data.tests;
    }
    return battery;
  }),
  addRelationTestBatterySuccess: action.data,
  addRelationTestBatteryLoading: false,
  addRelationTestBatteryError: null,
});
const receiveAddRelationTestBatteryError = (state = INITIAL_STATE, action) => ({
  ...state,
  addRelationTestBatterySuccess: null,
  addRelationTestBatteryLoading: false,
  addRelationTestBatteryError: action.error,
  statusErrorAdmin: action.error.status,
});

const requestRemoveRelationTestBattery = (state = INITIAL_STATE) => ({
  ...state,
  removeRelationTestBatterySuccess: null,
  removeRelationTestBatteryLoading: true,
  removeRelationTestBatteryError: null,
});

const receiveRemoveRelationTestBattery = (state = INITIAL_STATE, action) => ({
  ...state,
  batteriesData: state.batteriesData.map((battery) => {
    if (battery.id === action.data.batteryId) battery.tests = action.data.tests;
    return battery;
  }),
  removeRelationTestBatterySuccess: action.data,
  removeRelationTestBatteryLoading: false,
  removeRelationTestBatteryError: null,
});
const receiveRemoveRelationTestBatteryError = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  removeRelationTestBatterySuccess: null,
  removeRelationTestBatteryLoading: false,
  removeRelationTestBatteryError: action.error,
  statusErrorAdmin: action.error.status,
});

// Remove Battery

const requestRemoveBattery = (state = INITIAL_STATE) => ({
  ...state,
  removeBatterySuccess: null,
  removeBatteryLoading: true,
  removeBatteryError: null,
});

const receiveRemoveBattery = (state = INITIAL_STATE, action) => ({
  ...state,
  batteriesData: state.batteriesData.filter(
    (battery) => battery.id !== action.data.id
  ),
  removeBatterySuccess: action.data,
  removeBatteryLoading: false,
  removeBatteryError: null,
});

const receiveRemoveBatteryError = (state = INITIAL_STATE, action) => ({
  ...state,
  removeBatterySuccess: null,
  removeBatteryLoading: false,
  removeBatteryError: action.error,
  statusErrorAdmin: action.error.status,
});

const requestListVolunteers = (state = INITIAL_STATE) => ({
  ...state,
  listVolunteersData: null,
  listVolunteersLoading: true,
  listVolunteersError: null,
});
const receiveListVolunteers = (state = INITIAL_STATE, action) => ({
  ...state,
  listVolunteersData: action.data,
  listVolunteersLoading: false,
  listVolunteersError: null,
});
const receiveListVolunteersError = (state = INITIAL_STATE, action) => ({
  ...state,
  listVolunteersData: null,
  listVolunteersLoading: false,
  listVolunteersError: action.error,
});

const requestResendVolunteer = (state = INITIAL_STATE) => ({
  ...state,
  resendVolunteerData: null,
  resendVolunteerLoading: true,
  resendVolunteerError: null,
});

const receiveResendVolunteer = (state = INITIAL_STATE, action) => ({
  ...state,
  resendVolunteerData: action.data,
  listVolunteersData: state.listVolunteersData.map((volunteer) => {
    if (volunteer.id === action.data.volunteer.id) {
      volunteer.resendEmail = action.data.volunteer.resendEmail;
    }
    return volunteer;
  }),
  resendVolunteerLoading: false,
  resendVolunteerError: null,
});

const receiveResendVolunteerError = (state = INITIAL_STATE, action) => ({
  ...state,
  resendVolunteerData: null,
  resendVolunteerLoading: false,
  resendVolunteerError: action.error,
});

const requestCopyLinkVolunteer = (state = INITIAL_STATE) => ({
  ...state,
  copyLinkVolunteerData: null,
  copyLinkVolunteerLoading: true,
  copyLinkVolunteerError: null,
});

const receiveCopyLinkVolunteer = (state = INITIAL_STATE, action) => ({
  ...state,
  copyLinkVolunteerData: action.data,
  copyLinkVolunteerLoading: false,
  copyLinkVolunteerError: null,
});

const receiveCopyLinkVolunteerError = (state = INITIAL_STATE, action) => ({
  ...state,
  copyLinkVolunteerData: null,
  copyLinkVolunteerLoading: false,
  copyLinkVolunteerError: action.error,
});

const requestTeams = (state = INITIAL_STATE) => ({
  ...state,
  teamsData: null,
  teamsLoading: true,
  teamsError: null,
});

const receiveTeams = (state = INITIAL_STATE, action) => ({
  ...state,
  teamsData: action.data,
  teamsLoading: false,
  teamsError: null,
});

const receiveTeamsError = (state = INITIAL_STATE, action) => ({
  ...state,
  teamsData: null,
  teamsLoading: false,
  teamsError: action.error,
});

const requestCreateTeam = (state = INITIAL_STATE) => ({
  ...state,
  createTeamSuccess: null,
  createTeamLoading: true,
  createTeamError: null,
});

const receiveCreateTeam = (state = INITIAL_STATE, action) => ({
  ...state,
  teamsData: state.teamsData
    ? state.teamsData.concat([action.data])
    : [action.data],
  createTeamLoading: false,
  createTeamSuccess: action.data,
  createTeamError: null,
});

const receiveCreateTeamError = (state = INITIAL_STATE, action) => ({
  ...state,
  createTeamLoading: false,
  createTeamSuccess: null,
  createTeamError: action.error,
});

const requestEditTeam = (state = INITIAL_STATE) => ({
  ...state,
  editTeamSuccess: null,
  editTeamLoading: true,
  editTeamError: null,
});

const receiveEditTeam = (state = INITIAL_STATE, action) => ({
  ...state,
  teamsData: state.teamsData.map((team) => {
    return team.id === action.data.id ? action.data : team;
  }),
  editTeamLoading: false,
  editTeamSuccess: action.data,
  editTeamError: null,
});

const receiveEditTeamError = (state = INITIAL_STATE, action) => ({
  ...state,
  editTeamLoading: false,
  editTeamSuccess: null,
  editTeamError: action.error,
});

const requestRemoveTeam = (state = INITIAL_STATE) => ({
  ...state,
  removeTeamSuccess: null,
  removeTeamLoading: true,
  removeTeamError: null,
});

const receiveRemoveTeam = (state = INITIAL_STATE, action) => ({
  ...state,
  teamsData: state.teamsData.filter((team) => {
    return team.id !== action.data.id;
  }),
  removeTeamLoading: false,
  removeTeamSuccess: action.data,
  removeTeamError: null,
});

const receiveRemoveTeamError = (state = INITIAL_STATE, action) => ({
  ...state,
  removeTeamLoading: false,
  removeTeamSuccess: null,
  removeTeamError: action.error,
});

const requestAddMemberTeam = (state = INITIAL_STATE) => ({
  ...state,
  addMemberTeamSuccess: null,
  addMemberTeamLoading: true,
  addMemberTeamError: null,
});

const receiveAddMemberTeam = (state = INITIAL_STATE, action) => ({
  ...state,
  teamsData: state.teamsData.map((team) => {
    return team.id === action.data.id ? action.data : team;
  }),
  addMemberTeamSuccess: action.data,
  addMemberTeamLoading: false,
  addMemberTeamError: null,
});

const receiveAddMemberTeamError = (state = INITIAL_STATE, action) => ({
  ...state,
  addMemberTeamSuccess: null,
  addMemberTeamLoading: false,
  addMemberTeamError: action.error,
});

const requestRemoveMemberTeam = (state = INITIAL_STATE) => ({
  ...state,
  removeMemberTeamSuccess: null,
  removeMemberTeamLoading: true,
  removeMemberTeamError: null,
});

const receiveRemoveMemberTeam = (state = INITIAL_STATE, action) => ({
  ...state,
  teamsData: state.teamsData.map((team) => {
    return team.id === action.data.id ? action.data : team;
  }),
  removeMemberTeamSuccess: action.data,
  removeMemberTeamLoading: false,
  removeMemberTeamError: null,
});

const receiveRemoveMemberTeamError = (state = INITIAL_STATE, action) => ({
  ...state,
  removeMemberTeamSuccess: null,
  removeMemberTeamLoading: false,
  removeMemberTeamError: action.error,
});

const requestLabels = (state = INITIAL_STATE) => ({
  ...state,
  labelsData: null,
  labelsLoading: true,
  labelsError: null,
});

const receiveLabels = (state = INITIAL_STATE, action) => ({
  ...state,
  labelsData: action.data,
  labelsLoading: false,
  labelsError: null,
});

const receiveLabelsError = (state = INITIAL_STATE, action) => ({
  ...state,
  labelsData: null,
  labelsLoading: false,
  labelsError: action.error,
});

const requestCreateLabel = (state = INITIAL_STATE) => ({
  ...state,
  createLabelSuccess: null,
  createLabelLoading: true,
  createLabelError: null,
});

const receiveCreateLabel = (state = INITIAL_STATE, action) => ({
  ...state,
  createLabelSuccess: action.data,
  createLabelLoading: false,
  createLabelError: null,
  labelsData: state.labelsData
    ? state.labelsData.concat([action.data])
    : [action.data],
});

const receiveCreateLabelError = (state = INITIAL_STATE, action) => ({
  ...state,
  createLabelSuccess: null,
  createLabelLoading: false,
  createLabelError: action.error,
});

const requestEditLabel = (state = INITIAL_STATE) => ({
  ...state,
  editLabelSuccess: null,
  editLabelLoading: true,
  editLabelError: null,
});

const receiveEditLabel = (state = INITIAL_STATE, action) => ({
  ...state,
  editLabelSuccess: action.data,
  editLabelLoading: false,
  editLabelError: null,
  labelsData: state.labelsData?.map((label) => {
    return label.id === action.data.id ? action.data : label;
  }),
});

const receiveEditLabelError = (state = INITIAL_STATE, action) => ({
  ...state,
  editLabelSuccess: null,
  editLabelLoading: false,
  editLabelError: action.error,
});

const requestRemoveLabel = (state = INITIAL_STATE) => ({
  ...state,
  removeLabelSuccess: null,
  removeLabelLoading: true,
  removeLabelError: null,
});

const receiveRemoveLabel = (state = INITIAL_STATE, action) => ({
  ...state,
  removeLabelSuccess: action.data,
  removeLabelLoading: false,
  removeLabelError: null,
  labelsData: state.labelsData.filter((label) => {
    return label.id !== action.data.id;
  }),
});

const receiveRemoveLabelError = (state = INITIAL_STATE, action) => ({
  ...state,
  removeLabelSuccess: null,
  removeLabelLoading: false,
  removeLabelError: action.error,
});

const requestFeedbacks = (state = INITIAL_STATE) => ({
  ...state,
  feedbacksData: null,
  feedbacksLoading: true,
  feedbacksError: null,
});

const receiveFeedbacks = (state = INITIAL_STATE, action) => ({
  ...state,
  feedbacksData: action.data,
  feedbacksLoading: false,
  feedbacksError: null,
});

const receiveFeedbacksError = (state = INITIAL_STATE, action) => ({
  ...state,
  feedbacksData: null,
  feedbacksLoading: false,
  feedbacksError: action.error,
});

const requestCreateBatteryTestConnection = (state = INITIAL_STATE) => ({
  ...state,
  createBatteryTestConnectionLoading: true,
  createBatteryTestConnectionSuccess: false,
  createBatteryTestConnectionError: null,
});

const receiveCreateBatteryTestConnection = (state = INITIAL_STATE) => ({
  ...state,
  createBatteryTestConnectionLoading: false,
  createBatteryTestConnectionSuccess: true,
  createBatteryTestConnectionError: null,
});

const receiveCreateBatteryTestConnectionError = (
  state = INITIAL_STATE,
  action
) => ({
  ...state,
  createBatteryTestConnectionLoading: false,
  createBatteryTestConnectionSuccess: false,
  createBatteryTestConnectionError: action.error,
});

const restartCreateBatteryTestConnectionFlow = (state = INITIAL_STATE) => ({
  ...state,
  createBatteryTestConnectionLoading: false,
  createBatteryTestConnectionSuccess: false,
  createBatteryTestConnectionError: null,
});

const requestConfigParams = (state = INITIAL_STATE) => ({
  ...state,
  configParamsSuccess: false,
  configParamsLoading: false,
  configParamsError: null,
});

const receiveConfigParamsSuccess = (state = INITIAL_STATE) => ({
  ...state,
  configParamsSuccess: true,
  configParamsLoading: false,
  configParamsError: null,
});

const receiveConfigParamsError = (state = INITIAL_STATE, action) => ({
  ...state,
  configParamsSuccess: false,
  configParamsLoading: false,
  configParamsError: action.error,
});

const restartConfigParams = (state = INITIAL_STATE) => ({
  ...state,
  configParamsSuccess: false,
  configParamsLoading: false,
  configParamsError: null,
});

const requestConfigDemos = (state = INITIAL_STATE) => ({
  ...state,
  configDemosSuccess: false,
  configDemosLoading: false,
  configDemosError: null,
});

const receiveConfigDemos = (state = INITIAL_STATE) => ({
  ...state,
  configDemosSuccess: true,
  configDemosLoading: false,
  configDemosError: null,
});

const receiveConfigDemosError = (state = INITIAL_STATE, action) => ({
  ...state,
  configDemosSuccess: false,
  configDemosLoading: false,
  configDemosError: action.error,
});

const restartConfigDemos = (state = INITIAL_STATE) => ({
  ...state,
  configDemosSuccess: false,
  configDemosLoading: false,
  configDemosError: null,
});

const requestApplications = (state = INITIAL_STATE) => ({
  ...state,
  appsData: null,
  appsLoading: true,
  appsError: null,
});

const receiveApplications = (state = INITIAL_STATE, action) => ({
  ...state,
  appsData: action.data,
  appsLoading: false,
  appsError: null,
});

const receiveApplicationsError = (state = INITIAL_STATE, action) => ({
  ...state,
  appsData: null,
  appsLoading: false,
  appsError: action.error,
});

const requestEditApplication = (state = INITIAL_STATE) => ({
  ...state,
  editAppSuccess: false,
  editAppLoading: true,
  editAppError: null,
});

const receiveEditApplication = (state = INITIAL_STATE, action) => ({
  ...state,
  editAppSuccess: true,
  editAppLoading: false,
  editAppError: null,
});

const receiveEditApplicationError = (state = INITIAL_STATE, action) => ({
  ...state,
  editAppSuccess: false,
  editAppLoading: false,
  appsError: action.error,
});

const requestCreateApplication = (state = INITIAL_STATE) => ({
  ...state,
  createAppSuccess: false,
  createAppLoading: true,
  createAppError: null,
});

const receiveCreateApplication = (state = INITIAL_STATE, action) => ({
  ...state,
  createAppSuccess: true,
  createAppLoading: false,
  createAppError: null,
});

const receiveCreateApplicationError = (state = INITIAL_STATE, action) => ({
  ...state,
  createAppSuccess: false,
  createAppLoading: false,
  createAppError: action.error,
});

const requestDeleteApplication = (state = INITIAL_STATE) => ({
  ...state,
  deleteAppSuccess: false,
  deleteAppLoading: true,
  deleteAppError: null,
});

const receiveDeleteApplication = (state = INITIAL_STATE, action) => ({
  ...state,
  deleteAppSuccess: true,
  deleteAppLoading: false,
  deleteAppError: null,
});

const receiveDeleteApplicationError = (state = INITIAL_STATE, action) => ({
  ...state,
  deleteAppSuccess: false,
  deleteAppLoading: false,
  deleteAppError: action.error,
});

const resetApplicationFlow = (state = INITIAL_STATE) => ({
  ...state,
  editAppSuccess: false,
  editAppLoading: false,
  editAppError: null,
  createAppSuccess: false,
  createAppLoading: false,
  createAppError: null,
  deleteAppSuccess: false,
  deleteAppLoading: false,
  deleteAppError: null,
})
export default createReducer(INITIAL_STATE, {
  [Types.RESTART_STATUS_ERROR_ADMIN]: restartStatusErrorAdmin,
  [Types.RESTART_PERMISSIONS_FLOW]: restartPermissionsFlow,
  [Types.RESTART_CREATE_USER_FLOW]: restartCreateUserFlow,
  [Types.RESTART_REMOVE_USER_FLOW]: restartRemoveUserFlow,
  [Types.RESTART_EDIT_USER_FLOW]: restartEditUserFlow,
  [Types.RESTART_REMOVE_PERMISSION_FLOW]: restartRemovePermissionFlow,
  [Types.RESTART_EDIT_PERMISSION_FLOW]: restartEditPermissionFlow,
  [Types.RESTART_INVITE_VOLUNTEER_FLOW]: restartInviteVolunteerFlow,
  [Types.RESTART_CREATE_TEST_FLOW]: restartCreateTestFlow,
  [Types.RESTART_EDIT_TEST_FLOW]: restartEditTestFlow,
  [Types.RESTART_REMOVE_TEST_FLOW]: restartRemoveTestFlow,
  [Types.RESTART_CREATE_BATTERY_FLOW]: restartCreateBatteryFlow,
  [Types.RESTART_EDIT_BATTERY_FLOW]: restartEditBatteryFlow,
  [Types.RESTART_REMOVE_BATTERY_FLOW]: restartRemoveBatteryFlow,
  [Types.RESTART_ADD_RELATION_TEST_BATTERY_FLOW]:
    restartAddRelationTestBatteryFlow,
  [Types.RESTART_REMOVE_RELATION_TEST_BATTERY_FLOW]:
    restartRemoveRelationTestBatteryFlow,
  [Types.RESTART_COPY_LINK_VOLUNTEER]: restartCopyLinkVolunteer,
  [Types.RESTART_CREATE_TEAM_FLOW]: restartCreateTeamFlow,
  [Types.RESTART_EDIT_TEAM_FLOW]: restartEditTeamFlow,
  [Types.RESTART_REMOVE_TEAM_FLOW]: restartRemoveTeamFlow,
  [Types.RESTART_REMOVE_MEMBER_TEAM_FLOW]: restartRemoveMemberTeamFlow,
  [Types.RESTART_ADD_MEMBER_TEAM_FLOW]: restartAddMemberTeamFlow,
  [Types.RESTART_RESEND_EMAIL_FLOW]: restartResendEmailFlow,

  [Types.RESTART_CREATE_LABEL_FLOW]: restartCreateLabelFlow,
  [Types.RESTART_EDIT_LABEL_FLOW]: restartEditLabelFlow,
  [Types.RESTART_REMOVE_LABEL_FLOW]: restartRemoveLabelFlow,

  [Types.REQUEST_INVITE_VOLUNTEER]: requestInviteVolunteer,
  [Types.RECEIVE_INVITE_VOLUNTEER]: receiveInviteVolunteer,
  [Types.RECEIVE_INVITE_VOLUNTEER_ERROR]: receiveInviteVolunteerError,

  [Types.REQUEST_GET_LIST_OF_USERS]: requestGetListOfUsers,
  [Types.RECEIVE_GET_LIST_OF_USERS]: receiveGetListOfUsers,
  [Types.RECEIVE_GET_LIST_OF_USERS_ERROR]: receiveGetListOfUsersError,

  [Types.REQUEST_CHANGE_USER_PERMISSION]: requestChangeUserPermission,
  [Types.RECEIVE_CHANGE_USER_PERMISSION]: receiveChangeUserPermission,
  [Types.RECEIVE_CHANGE_USER_PERMISSION_ERROR]:
    receiveChangeUserPermissionError,

  [Types.REQUEST_CREATE_USER]: requestCreateUser,
  [Types.RECEIVE_CREATE_USER]: receiveCreateUser,
  [Types.RECEIVE_CREATE_USER_ERROR]: receiveCreateUserError,

  [Types.REQUEST_EDIT_USER]: requestEditUser,
  [Types.RECEIVE_EDIT_USER]: receiveEditUser,
  [Types.RECEIVE_EDIT_USER_ERROR]: receiveEditUserError,

  [Types.REQUEST_REMOVE_USER]: requestRemoveUser,
  [Types.RECEIVE_REMOVE_USER]: receiveRemoveUser,
  [Types.RECEIVE_REMOVE_USER_ERROR]: receiveRemoveUserError,

  [Types.REQUEST_PERMISSIONS]: requestPermissions,
  [Types.RECEIVE_PERMISSIONS]: receivePermissions,
  [Types.RECEIVE_PERMISSIONS_ERROR]: receivePermissionsError,

  [Types.REQUEST_CREATE_PERMISSION]: requestCreatePermission,
  [Types.RECEIVE_CREATE_PERMISSION]: receiveCreatePermission,
  [Types.RECEIVE_CREATE_PERMISSION_ERROR]: receiveCreatePermissionError,

  [Types.REQUEST_REMOVE_PERMISSION]: requestRemovePermission,
  [Types.RECEIVE_REMOVE_PERMISSION]: receiveRemovePermission,
  [Types.RECEIVE_REMOVE_PERMISSION_ERROR]: receiveRemovePermissionError,

  [Types.REQUEST_EDIT_PERMISSION]: requestEditPermission,
  [Types.RECEIVE_EDIT_PERMISSION]: receiveEditPermission,
  [Types.RECEIVE_EDIT_PERMISSION_ERROR]: receiveEditPermissionError,

  [Types.REQUEST_TESTS]: requestTests,
  [Types.RECEIVE_TESTS]: receiveTests,
  [Types.RECEIVE_TESTS_ERROR]: receiveTestsError,

  [Types.REQUEST_CREATE_TEST]: requestCreateTest,
  [Types.RECEIVE_CREATE_TEST]: receiveCreateTest,
  [Types.RECEIVE_CREATE_TEST_ERROR]: receiveCreateTestError,

  [Types.REQUEST_EDIT_TEST]: requestEditTest,
  [Types.RECEIVE_EDIT_TEST]: receiveEditTest,
  [Types.RECEIVE_EDIT_TEST_ERROR]: receiveEditTestError,

  [Types.REQUEST_REMOVE_TEST]: requestRemoveTest,
  [Types.RECEIVE_REMOVE_TEST]: receiveRemoveTest,
  [Types.RECEIVE_REMOVE_TEST_ERROR]: receiveRemoveTestError,

  [Types.REQUEST_REORGANIZE_TESTS]: requestReorganizeTests,
  [Types.RECEIVE_REORGANIZE_TESTS]: receiveReorganizeTests,
  [Types.RECEIVE_REORGANIZE_TESTS_ERROR]: receiveReorganizeTestsError,

  [Types.REQUEST_BATTERIES]: requestBatteries,
  [Types.RECEIVE_BATTERIES]: receiveBatteries,
  [Types.RECEIVE_BATTERIES_ERROR]: receiveBatteriesError,

  [Types.REQUEST_CREATE_BATTERY]: requestCreateBattery,
  [Types.RECEIVE_CREATE_BATTERY]: receiveCreateBattery,
  [Types.RECEIVE_CREATE_BATTERY_ERROR]: receiveCreateBatteryError,

  [Types.REQUEST_EDIT_BATTERY]: requestEditBattery,
  [Types.RECEIVE_EDIT_BATTERY]: receiveEditBattery,
  [Types.RECEIVE_EDIT_BATTERY_ERROR]: receiveEditBatteryError,

  [Types.REQUEST_REMOVE_BATTERY]: requestRemoveBattery,
  [Types.RECEIVE_REMOVE_BATTERY]: receiveRemoveBattery,
  [Types.RECEIVE_REMOVE_BATTERY_ERROR]: receiveRemoveBatteryError,

  [Types.REQUEST_ADD_RELATION_TEST_BATTERY]: requestAddRelationTestBattery,
  [Types.RECEIVE_ADD_RELATION_TEST_BATTERY]: receiveAddRelationTestBattery,
  [Types.RECEIVE_ADD_RELATION_TEST_BATTERY_ERROR]:
    receiveAddRelationTestBatteryError,

  [Types.REQUEST_REMOVE_RELATION_TEST_BATTERY]:
    requestRemoveRelationTestBattery,
  [Types.RECEIVE_REMOVE_RELATION_TEST_BATTERY]:
    receiveRemoveRelationTestBattery,
  [Types.RECEIVE_REMOVE_RELATION_TEST_BATTERY_ERROR]:
    receiveRemoveRelationTestBatteryError,

  [Types.REQUEST_VERIFY_USER]: requestVerifyUser,
  [Types.RECEIVE_VERIFY_USER]: receiveVerifyUser,
  [Types.RECEIVE_VERIFY_USER_ERROR]: receiveVerifyUserError,
  [Types.RESTART_VERIFY_USER]: restartVerifyUser,

  [Types.REQUEST_LIST_VOLUNTEERS]: requestListVolunteers,
  [Types.RECEIVE_LIST_VOLUNTEERS]: receiveListVolunteers,
  [Types.RECEIVE_LIST_VOLUNTEERS_ERROR]: receiveListVolunteersError,

  [Types.REQUEST_RESEND_VOLUNTEER]: requestResendVolunteer,
  [Types.RECEIVE_RESEND_VOLUNTEER]: receiveResendVolunteer,
  [Types.RECEIVE_RESEND_VOLUNTEER_ERROR]: receiveResendVolunteerError,

  [Types.REQUEST_COPY_LINK_VOLUNTEER]: requestCopyLinkVolunteer,
  [Types.RECEIVE_COPY_LINK_VOLUNTEER]: receiveCopyLinkVolunteer,
  [Types.RECEIVE_COPY_LINK_VOLUNTEER_ERROR]: receiveCopyLinkVolunteerError,

  [Types.REQUEST_TEAMS]: requestTeams,
  [Types.RECEIVE_TEAMS]: receiveTeams,
  [Types.RECEIVE_TEAMS_ERROR]: receiveTeamsError,

  [Types.REQUEST_CREATE_TEAM]: requestCreateTeam,
  [Types.RECEIVE_CREATE_TEAM]: receiveCreateTeam,
  [Types.RECEIVE_CREATE_TEAM_ERROR]: receiveCreateTeamError,

  [Types.REQUEST_EDIT_TEAM]: requestEditTeam,
  [Types.RECEIVE_EDIT_TEAM]: receiveEditTeam,
  [Types.RECEIVE_EDIT_TEAM_ERROR]: receiveEditTeamError,

  [Types.REQUEST_REMOVE_TEAM]: requestRemoveTeam,
  [Types.RECEIVE_REMOVE_TEAM]: receiveRemoveTeam,
  [Types.RECEIVE_REMOVE_TEAM_ERROR]: receiveRemoveTeamError,

  [Types.REQUEST_ADD_MEMBER_TEAM]: requestAddMemberTeam,
  [Types.RECEIVE_ADD_MEMBER_TEAM]: receiveAddMemberTeam,
  [Types.RECEIVE_ADD_MEMBER_TEAM_ERROR]: receiveAddMemberTeamError,

  [Types.REQUEST_REMOVE_MEMBER_TEAM]: requestRemoveMemberTeam,
  [Types.RECEIVE_REMOVE_MEMBER_TEAM]: receiveRemoveMemberTeam,
  [Types.RECEIVE_REMOVE_MEMBER_TEAM_ERROR]: receiveRemoveMemberTeamError,

  [Types.REQUEST_LABELS]: requestLabels,
  [Types.RECEIVE_LABELS]: receiveLabels,
  [Types.RECEIVE_LABELS_ERROR]: receiveLabelsError,

  [Types.REQUEST_CREATE_LABEL]: requestCreateLabel,
  [Types.RECEIVE_CREATE_LABEL]: receiveCreateLabel,
  [Types.RECEIVE_CREATE_LABEL_ERROR]: receiveCreateLabelError,

  [Types.REQUEST_EDIT_LABEL]: requestEditLabel,
  [Types.RECEIVE_EDIT_LABEL]: receiveEditLabel,
  [Types.RECEIVE_EDIT_LABEL_ERROR]: receiveEditLabelError,

  [Types.REQUEST_REMOVE_LABEL]: requestRemoveLabel,
  [Types.RECEIVE_REMOVE_LABEL]: receiveRemoveLabel,
  [Types.RECEIVE_REMOVE_LABEL_ERROR]: receiveRemoveLabelError,

  [Types.REQUEST_FEEDBACKS]: requestFeedbacks,
  [Types.RECEIVE_FEEDBACKS]: receiveFeedbacks,
  [Types.RECEIVE_FEEDBACKS_ERROR]: receiveFeedbacksError,

  [Types.REQUEST_CREATE_BATTERY_TEST_CONNECTION]:
    requestCreateBatteryTestConnection,
  [Types.RECEIVE_CREATE_BATTERY_TEST_CONNECTION_ERROR]:
    receiveCreateBatteryTestConnectionError,
  [Types.RECEIVE_CREATE_BATTERY_TEST_CONNECTION]:
    receiveCreateBatteryTestConnection,
  [Types.RESTART_CREATE_BATTERY_TEST_CONNECTION_FLOW]:
    restartCreateBatteryTestConnectionFlow,

  [Types.REQUEST_CONFIG_PARAMS]: requestConfigParams,
  [Types.RECEIVE_CONFIG_PARAMS_SUCCESS]: receiveConfigParamsSuccess,
  [Types.RECEIVE_CONFIG_PARAMS_ERROR]: receiveConfigParamsError,
  [Types.RESTART_CONFIG_PARAMS]: restartConfigParams,

  [Types.REQUEST_CONFIG_DEMOS]: requestConfigDemos,
  [Types.RECEIVE_CONFIG_DEMOS_SUCCESS]: receiveConfigDemos,
  [Types.RECEIVE_CONFIG_DEMOS_ERROR]: receiveConfigDemosError,
  [Types.RESTART_CONFIG_DEMOS]: restartConfigDemos,

  [Types.REQUEST_APPLICATIONS]: requestApplications,
  [Types.RECEIVE_APPLICATIONS]: receiveApplications,
  [Types.RECEIVE_APPLICATIONS_ERROR]: receiveApplicationsError,

  [Types.REQUEST_EDIT_APPLICATION]: requestEditApplication,
  [Types.RECEIVE_EDIT_APPLICATION]: receiveEditApplication,
  [Types.RECEIVE_EDIT_APPLICATION_ERROR]: receiveEditApplicationError,

  [Types.REQUEST_CREATE_APPLICATION]: requestCreateApplication,
  [Types.RECEIVE_CREATE_APPLICATION]: receiveCreateApplication,
  [Types.RECEIVE_CREATE_APPLICATION_ERROR]: receiveCreateApplicationError,

  [Types.REQUEST_DELETE_APPLICATION]: requestDeleteApplication,
  [Types.RECEIVE_DELETE_APPLICATION]: receiveDeleteApplication,
  [Types.RECEIVE_DELETE_APPLICATION_ERROR]: receiveDeleteApplicationError,

  [Types.RESET_APPLICATION_FLOW]: resetApplicationFlow

});
