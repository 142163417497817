import { createReducer } from "reduxsauce";
import { Types } from "./actions";

const INITIAL_STATE = {
    executedBatteriesData: null,
    executedBatteriesLoading: false,
    executedBatteriesError: null,
}


const requestExecutedBatteries = (state) => ({
    ...state,
    executedBatteriesData: null,
    executedBatteriesLoading: true,
    executedBatteriesError: null,
})

const receiveExecutedBatteries = (state, action) => ({
    ...state,
    executedBatteriesData: action.data,
    executedBatteriesLoading: false,
    executedBatteriesError: null,
})

const receiveExecutedBatteriesError = (state, action) => ({
    ...state,
    executedBatteriesData: null,
    executedBatteriesLoading: false,
    executedBatteriesError: action.error
})

export default createReducer(INITIAL_STATE, {
    [Types.REQUEST_EXECUTED_BATTERIES]: requestExecutedBatteries,
    [Types.RECEIVE_EXECUTED_BATTERIES]: receiveExecutedBatteries,
    [Types.RECEIVE_EXECUTED_BATTERIES_ERROR]: receiveExecutedBatteriesError,
})