import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
    resetTypeProcessingState: null,

    requestTypeProcessing: null,
    receiveTypeProcessing: ['data'],
    receiveTypeProcessingError: ['error'],

    requestCreateTypeProcessing: null,
    receiveCreateTypeProcessing: ['data'],
    receiveCreateTypeProcessingError: ['error'],

    requestEditTypeProcessing: null,
    receiveEditTypeProcessing: ['data'],
    receiveEditTypeProcessingError: ['error'],

    requestRemoveTypeProcessing: null,
    receiveRemoveTypeProcessing: ['data'],
    receiveRemoveTypeProcessingError: ['error'],
});
