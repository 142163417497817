import { Creators } from './actions';
import { request } from '../../config/Http';
import { API_URL } from '../api';

export const Async = {
  getExecutedBatteries: () => async (dispatch) => {
    dispatch(Creators.requestExecutedBatteries());
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/battery/list-batteries-executed`,
      });
      dispatch(Creators.receiveExecutedBatteries(data));
    } catch (e) {
      dispatch(Creators.receiveExecutedBatteriesError(e));
    }
  },
};
