import { createReducer } from 'reduxsauce';
import {Types} from './actions';

const INITIAL_STATE = {
  sessionData: null,
  sessionLoading: false,
  sessionError: null,

  nextTestData: null,
  nextTestLoading: false,
  nextTestError: null,

  isPatientData: null,
  isPatientLoading: false,
  isPatientError: null,

  finishedTests: null,
  finishedTestsLoading: false,
  finishedTestsError: null,

  sessionExistSuccess: null,
  sessionExistLoading: false,
  sessionExistError: null,

  getSessionData: null,
  getSessionLoading: false,
  getSessionError: null,

  getBatteryData: null,
  getBatteryLoading: false,
  getBatteryError: null
}

const resetTestState = () => ({
  sessionData: null,
  sessionLoading: false,
  sessionError: null,

  nextTestData: null,
  nextTestLoading: false,
  nextTestError: null,

  isPatientData: null,
  isPatientLoading: false,
  isPatientError: null,

  finishedTests: null,
  finishedTestsError: null,
  finishedTestsLoading: false,

  sessionExistSuccess: null,
  sessionExistLoading: false,
  sessionExistError: null,

  getSessionData: null,
  getSessionLoading: false,
  getSessionError: null,

  getBatteryData: null,
  getBatteryLoading: false,
  getBatteryError: null
})
// Init Session
const requestInitSession = (state = INITIAL_STATE) => ({
  ...state,
  sessionData: null,
  sessionLoading: true,
  sessionError: null,
})

const receiveInitSession = (state = INITIAL_STATE, action) => ({
  ...state,
  sessionData: action.data,
  sessionLoading: false,
  sessionError: null
})

const receiveInitSessionError = (state = INITIAL_STATE, action) => ({
  ...state,
  sessionData: null,
  sessionLoading: false,
  sessionError: action.error
})

const requestSessionExist = (state = INITIAL_STATE) => ({
  ...state,
  sessionExistSuccess: null,
  sessionExistLoading: true,
  sessionExistError: null
});

const receiveSessionExist = (state = INITIAL_STATE, action) => ({
  ...state,
  sessionExistSuccess: action.data,
  sessionExistLoading: false,
  sessionExistError: null
});

const receiveSessionExistError = (state = INITIAL_STATE, action) => ({
  ...state,
  sessionExistSuccess: null,
  sessionExistLoading: false,
  sessionExistError: action.error
});
// Next Test
const requestNextTest = (state = INITIAL_STATE) => ({
  ...state,
  nextTestData: null,
  nextTestLoading: true,
  nextTestError: null,
})

const receiveNextTest = (state = INITIAL_STATE, action) => ({
  ...state,
  nextTestData: action.data,
  nextTestLoading: false,
  nextTestError: null,
})

const receiveNextTestError = (state = INITIAL_STATE, action) => ({
  ...state,
  nextTestData: null,
  nextTestLoading: false,
  nextTestError: action.error,
})


// Is patient

const requestIsPatient = (state = INITIAL_STATE) => ({
  ...state,
  isPatientData: null,
  isPatientLoading: true,
  isPatientError: null,
})

const receiveIsPatient = (state = INITIAL_STATE, action) => ({
  ...state,
  isPatientData: action.data,
  isPatientLoading: false,
  isPatientError: null,
})

const receiveIsPatientError = (state = INITIAL_STATE, action) => ({
  ...state,
  isPatientData: null,
  isPatientLoading: false,
  isPatientError: action.error,
})

const requestFinishedTests = (state = INITIAL_STATE) => ({
  ...state,
  finishedTests: null,
  finishedTestsLoading: true,
  finishedTestsError: null,
})

const receiveFinishedTests = (state = INITIAL_STATE, action) => ({
  ...state,
  finishedTests: action.data,
  finishedTestsLoading: false,
  finishedTestsError: null,
})

const receiveFinishedTestsError = (state = INITIAL_STATE, action) => ({
  ...state,
  finishedTests: null,
  finishedTestsLoading: false,
  finishedTestsError: action.error,
})


const requestGetSession = (state = INITIAL_STATE) => ({
  ...state,
  getSessionData: null,
  getSessionLoading: true,
  getSessionError: null
})

const receiveGetSession = (state = INITIAL_STATE, action) => ({
  ...state,
  getSessionData: action.data,
  getSessionLoading: false,
  getSessionError: null
})

const receiveGetSessionError = (state = INITIAL_STATE, action) => ({
  ...state,
  getSessionData: null,
  getSessionLoading: false,
  getSessionError: action.error
})

const requestGetBattery = (state = INITIAL_STATE) => ({
  ...state,
  getBatteryData: null,
  getBatteryLoading: true,
  getBatteryError: null
})

const receiveGetBattery = (state = INITIAL_STATE, action) => ({
  ...state,
  getBatteryData: action.data,
  getBatteryLoading: false,
  getBatteryError: null
})

const receiveGetBatteryError = (state = INITIAL_STATE, action) => ({
  ...state,
  getBatteryData: null,
  getBatteryLoading: false,
  getBatteryError: action.error
})

export default createReducer(INITIAL_STATE, {
  [Types.RESET_TEST_STATE]: resetTestState,
  [Types.REQUEST_INIT_SESSION]: requestInitSession,
  [Types.RECEIVE_INIT_SESSION]: receiveInitSession,
  [Types.RECEIVE_INIT_SESSION_ERROR]: receiveInitSessionError,

  [Types.REQUEST_NEXT_TEST]: requestNextTest,
  [Types.RECEIVE_NEXT_TEST]: receiveNextTest,
  [Types.RECEIVE_NEXT_TEST_ERROR]: receiveNextTestError,

  [Types.REQUEST_IS_PATIENT]: requestIsPatient,
  [Types.RECEIVE_IS_PATIENT]: receiveIsPatient,
  [Types.RECEIVE_IS_PATIENT_ERROR]: receiveIsPatientError,

  [Types.REQUEST_FINISHED_TESTS]: requestFinishedTests,
  [Types.RECEIVE_FINISHED_TESTS]: receiveFinishedTests,
  [Types.RECEIVE_FINISHED_TESTS_ERROR]: receiveFinishedTestsError,

  [Types.REQUEST_SESSION_EXIST]: requestSessionExist,
  [Types.RECEIVE_SESSION_EXIST]: receiveSessionExist,
  [Types.RECEIVE_SESSION_EXIST_ERROR]: receiveSessionExistError,

  [Types.REQUEST_GET_SESSION]: requestGetSession,
  [Types.RECEIVE_GET_SESSION]: receiveGetSession,
  [Types.RECEIVE_GET_SESSION_ERROR]: receiveGetSessionError,

  [Types.REQUEST_GET_BATTERY]: requestGetBattery,
  [Types.RECEIVE_GET_BATTERY]: receiveGetBattery,
  [Types.RECEIVE_GET_BATTERY_ERROR]: receiveGetBatteryError,

})
