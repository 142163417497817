import { createStore, compose, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "../store";

const history = createBrowserHistory();
const middleware = [thunkMiddleware];
const reduxToolsCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers =
  typeof window === 'object' && reduxToolsCompose ? reduxToolsCompose : compose;

if (process.env.NODE_ENV === 'development') middleware.push(createLogger());
middleware.push(routerMiddleware(history));

const enHanceCreateStore = compose(
  composeEnhancers(applyMiddleware(...middleware))
)(createStore);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'user'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));
const store = enHanceCreateStore(persistedReducer);
const persistor = persistStore(store);

export { history, store, persistor };
