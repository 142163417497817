import { createReducer } from 'reduxsauce';
import { Types } from './actions';

const INITIAL_STATE = {
    batteryData: null,
    batteryDataError: null,

    reportData: null,
    reportLoading: false,
    reportError: null,
}

const requestBatteryData = (state = INITIAL_STATE) => ({
    ...state,
    batteryData: null,
    batteryDataError: null,
})

const receiveBatteryData = (state = INITIAL_STATE, action) => ({
    ...state,
    batteryData: action.data,
})

const receiveBatteryDataError = (state = INITIAL_STATE, action) => ({
    ...state,
    batteryDataError: action.error
})

const requestReportData = (state = INITIAL_STATE) => ({
    ...state,
    reportData: null,
    reportLoading: true,
    reportError: null,
})

const receiveReportData = (state = INITIAL_STATE, action) => ({
    ...state,
    reportData: action.data,
    reportLoading: false,
})

const receiveReportDataError = (state = INITIAL_STATE, action) => ({
    ...state,
    reportError: action.error,
    reportLoading: false,
})

export default createReducer(INITIAL_STATE, {
    [Types.REQUEST_BATTERY_DATA]: requestBatteryData,
    [Types.RECEIVE_BATTERY_DATA]: receiveBatteryData,
    [Types.RECEIVE_BATTERY_DATA_ERROR]: receiveBatteryDataError,

    [Types.REQUEST_REPORT_DATA]: requestReportData,
    [Types.RECEIVE_REPORT_DATA]: receiveReportData,
    [Types.RECEIVE_REPORT_DATA_ERROR]: receiveReportDataError,
})
