import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions({
  resetTestState: null,
  requestInitSession: null,
  receiveInitSession: ['data'],
  receiveInitSessionError: ['error'],

  requestNextTest: null,
  receiveNextTest: ['data'],
  receiveNextTestError: ['error'],

  requestSendReport: null,
  receiveSendReport: ['data'],
  receiveSendReportError: ['error'],

  requestSessionExist: null,
  receiveSessionExist: ['data'],
  receiveSessionExistError: ['error'],

  requestFinishedTests: null,
  receiveFinishedTests: ['data'],
  receiveFinishedTestsError: ['error'],

  requestSendBatteryFeedback: null,
  receiveSendBatteryFeedback: ['data'],
  receiveSendBatteryFeedbackError: ['error'],

  requestHasFeedback: null,
  receiveHasFeedback: ['data'],
  receiveHasFeedbackError: ['error'],

  requestGetPublicSession: null,
  receiveGetPublicSession: ['data'],
  receiveGetPublicSessionError: ['error'],

  resetSendBatteryLinkState: null,
  requestSendBatteryLinkToEmail: null,
  receiveSendBatteryLinkToEmail: null,
  receiveSendBatteryLinkToEmailError: ['error'],
});
