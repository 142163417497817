import { request } from '../../config/Http';
import { API_URL } from '../api';
import axios from 'axios';
import {Creators} from './actions'

export const Async = {
  resetAuthState: () => dispatch => {
    dispatch(Creators.resetAuthState())
  },
  getTestData: ({ id }) => async dispatch => {
    dispatch(Creators.requestGetTestData());
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/test?id=${id}`
      });
      dispatch(Creators.receiveGetTestData(data));
    } catch (e) {
      dispatch(Creators.receiveGetTestDataError(e));
    }
  },
  verifyTokenForResetPassword: ({ token }) => async dispatch => {
    dispatch(Creators.requestAuthForResetPassword())
    try {
      await axios({
        method: 'GET',
        url: `${API_URL}/user/auth`,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      dispatch(Creators.receiveAuthForResetPassword(token))
    } catch (e) {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      dispatch(Creators.receiveAuthForResetPasswordError(e))
    }
  },
  verifyToken: () => async dispatch => {
    dispatch(Creators.requestAuth())
    const token = localStorage.getItem('token')
    try {
      if (!token) {
        dispatch(Creators.resetAuthState())
      }
      else {
        const { data } = await request({
          method: 'GET',
          url: `${API_URL}/user/auth`,
        })

        localStorage.setItem('user', JSON.stringify(data.user))
        dispatch(Creators.receiveAuth(token, data.user))
      }
    } catch (e) {
      dispatch(Creators.resetAuthState())
      dispatch(Creators.receiveAuthError(e))
    }
  },
  login: ({ email, password }, setUserInformation) => async dispatch => {
    dispatch(Creators.requestLogin())
    try {
      const { data } = await request({
        method: 'POST',
        url: `${API_URL}/user/login`,
        data: {
          email,
          password
        }
      })
      const token = data.token.replace(/^Bearer /, '')

      localStorage.setItem('token', token)
      localStorage.setItem('user', JSON.stringify(data))

      setUserInformation(data)
      dispatch(Creators.receiveLogin(token, data))
    } catch (e) {
      localStorage.removeItem('token')
      localStorage.removeItem('isNotVerifiedToken')
      localStorage.removeItem('user')
      dispatch(Creators.receiveLoginError(e))
    }
  },
  register: ({ name, phoneNumber, cpf, areaOfExpertise, identificationNumber, gender, email, password, city, birthdate }, preUserToken) => async dispatch => {
    dispatch(Creators.requestRegister())
    try {
      const { data } = await request({
        method: 'POST',
        url: `${API_URL}/user/register`,
        data: {
          name,
          phoneNumber,
          cpf,
          areaOfExpertise,
          identificationNumber,
          gender,
          email,
          password,
          city,
          birthdate
        },
        params: {
          preUserToken: preUserToken ? preUserToken: undefined
        }
      })
      dispatch(Creators.receiveRegister(data))
    } catch (e) {
      dispatch(Creators.receiveRegisterError(e))
    }
  },
  forgotPassword: ({ email }) => async dispatch => {
    dispatch(Creators.requestForgotPassword())
    try {
      const { data } = await request({
        method: 'POST',
        url: `${API_URL}/user/forgot-password`,
        data: {
          email
        }
      })
      dispatch(Creators.receiveForgotPassword(data))
    } catch (e) {
      dispatch(Creators.receiveForgotPasswordError(e))
    }
  },
  resetPassword: ({ newPassword, token }) => async dispatch => {
    dispatch(Creators.requestResetPassword())
    try {
      const { data } = await axios({
        method: 'POST',
        url: `${API_URL}/user/reset-password`,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        },
        data: {
          newPassword
        }
      })
      dispatch(Creators.receiveResetPassword(data))
    } catch (e) {
      dispatch(Creators.receiveResetPasswordError(e))
    }
  },
  sendNewEmailVerified: ({ email }) => async dispatch => {
    dispatch(Creators.requestSendNewEmailVerified())
    try {
      const { data } = await request({
        method: 'POST',
        url: `${API_URL}/user/send-new-email-verified`,
        data: {
          email: email,
        }
      })
      dispatch(Creators.receiveSendNewEmailVerified(data))
    } catch (e) {
      dispatch(Creators.receiveSendNewEmailVerifiedError(e))
    }
  },
  verifyEmail: ({ emailToken }) => async dispatch => {
    dispatch(Creators.requestVerifyEmail())
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/user/verify-email?emailToken=${emailToken}`
      })
      dispatch(Creators.receiveVerifyEmail(data))
    } catch (e) {
      dispatch(Creators.receiveVerifyEmailError(e))
    }
  },
  isPreUser: ({preUserToken}) => async dispatch => {
    dispatch(Creators.requestIsPreUser())
    try {
      const { data } = await request({
        method: 'GET',
        url: `${API_URL}/user/is-pre-user?token=${preUserToken}`
      })
      dispatch(Creators.receiveIsPreUser(data))
    } catch (e) {
      dispatch(Creators.receiveIsPreUserError(e))
    }
  }
}
